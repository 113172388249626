import { createStore, applyMiddleware, combineReducers } from 'redux';
import createOidcMiddleware, { createUserManager, reducer } from 'redux-oidc';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

const oidcConfig = {
	authority: `${process.env.REACT_APP_JWT_DOMAIN}/authorize`,
	client_id: process.env.REACT_APP_JWT_AUDIENCE,
	redirect_uri: `${window.location.origin}/callback`,
	post_logout_redirect_uri: `${window.location.origin}`,
	response_type: 'token id_token',
	scope: 'openid',
	loadUserInfo: true,
	// Special case for Auth0
	metadata: {
		issuer: `${process.env.REACT_APP_JWT_DOMAIN}/`,
		authorization_endpoint: `${process.env.REACT_APP_JWT_DOMAIN}/authorize`,
		userinfo_endpoint: `${process.env.REACT_APP_JWT_DOMAIN}/userinfo`,
		end_session_endpoint: `${process.env.REACT_APP_JWT_DOMAIN}/v2/logout?returnTo=${encodeURIComponent(window.location.origin)}&client_id=${encodeURIComponent(process.env.REACT_APP_JWT_AUDIENCE)}`,
		jwks_uri: `${process.env.REACT_APP_JWT_DOMAIN}/.well-known/jwks.json`,
		token_endpoint: `${process.env.REACT_APP_JWT_DOMAIN}/oauth/token`
  	}
};

const userManager = createUserManager(oidcConfig);
const oidcMiddleware = createOidcMiddleware(userManager, () => true, false, '/callback');
const middlewares = process.env.NODE_ENV !== 'production' ? [reduxImmutableStateInvariant(), thunk, oidcMiddleware] : [thunk, oidcMiddleware];

const rootReducer = combineReducers({
	oidc: reducer
});

const store = createStore(rootReducer, undefined, applyMiddleware(...middlewares));

export { userManager };
export default store;
