/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';
import { useFetch } from '../common/hooks';

const cformatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' });

const StoreMonthReport = ({ store, month, timestamp }) => {
    const [{ data, loading, refresh, url }, setUrl] = useFetch(null);
    useEffect(() => {
        if (!loading) {
            if (url) {
                refresh();
            } else {
                setUrl(`${process.env.REACT_APP_API}/store/${store}/revenue/${month}`);
            }
        }
    }, [timestamp]);
    useEffect(() => {
        setUrl(`${process.env.REACT_APP_API}/store/${store}/revenue/${month}`);
    }, [month]);

    if (!data || loading) {
        return (
            <TableCell padding='none' align='center' colSpan={7}>
                <CircularProgress size={25}/>
            </TableCell>
        );
    }
    if (!data.ok) {
        return (
            <TableCell align='center' colSpan={7}>
				ei dataa
            </TableCell>
        );
    }

    return (
		<>
			<TableCell padding='none' align='right'>{data.receiptcount.value}</TableCell>
			<TableCell padding='none' align='center'>{data.receiptcount.delta && `${data.receiptcount.delta.replace('.', ',')} %`}</TableCell>
			<TableCell padding='none' align='right'>{cformatter.format(data.sales.value)}</TableCell>
			<TableCell padding='none' align='center'>{data.sales.delta && `${data.sales.delta.replace('.', ',')} %`}</TableCell>
			<TableCell padding='none' align='right'>{cformatter.format(data.cumulative.value)}</TableCell>
			<TableCell padding='none' align='center'>{data.cumulative.delta && `${data.cumulative.delta.replace('.', ',')} %`}</TableCell>
			<TableCell padding='checkbox' align='center'>
			    {data.cumulative.complete === false && (
					<>
						<Tooltip title='Data osittainen'>
						    <WarningIcon />
						</Tooltip>
						<Typography variant='srOnly'>Data osittainen</Typography>
					</>
			    )}
			</TableCell>
		</>
    );
};

export default StoreMonthReport;
