import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Calendar } from '@material-ui/pickers';
import moment from 'moment';

const OverrideDaySelector = ({ open, theme, onClose, onClick }) =>  {
    const [day, setDay] = useState(null);
    const [name, setName] = useState('');
    useEffect(() => {
        if (open) {
            setDay(null);
            setName('');
        }
    }, [open]);

    const handleDaySelect = (newDay, modifiers = {}) => {
        if (modifiers.disabled) {
            return;
        }
        setDay(newDay);
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
				Lisää poikkeuspäivä
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    type='text'
                    id='overrideDayName'
                    name='overrideDayName'
                    label='Nimi'
                    fullWidth
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    margin='normal'
                />
                <Calendar style={theme.calendar}
                    date={day ? moment(day) : moment()}
                    onChange={handleDaySelect}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
					Peruuta
                </Button>
                <Button color='primary'
                    onClick={() => onClick(day.toISOString().split('T')[0], name)}
                    disabled={day === null}
                >
					Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OverrideDaySelector;
