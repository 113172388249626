import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { months } from '../constants';
import { useFetch } from '../common/hooks';
import moment from 'moment';

const cformatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' });
const fformatter = new Intl.NumberFormat('fi-FI', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const aformatter = new Intl.NumberFormat('fi-FI', { maximumFractionDigits: 1 });

const ProfitInfo = ({ store }) => {
    const [{ data }, setUrl] = useFetch(null);
    const monthlydata = data ? data.items : [];
    const [firstElement, setFirstElement] = useState(0);
    const [rowsPerPage] = useState(13);
    const currentYear = {
        rent: 0,
        turnover: 0,
        sales: 0,
        surfacearea: 0,
        receipts: 0,
        months: 0
    };
    const lastTwelveMonths = {
        rent: 0,
        turnover: 0,
        sales: 0,
        surfacearea: 0,
        receipts: 0
    };
    monthlydata.forEach(item => {
        if(moment(item.date).year() === moment().year() && item.rentdata.surfacearea !== 0) {
            currentYear.rent += item.rentdata.netrent + item.rentdata.servicecharges;
            currentYear.turnover += parseFloat(item.turnover);
            currentYear.sales += parseFloat(item.sales);
            currentYear.surfacearea += item.rentdata.surfacearea;
            currentYear.receipts += item.receiptcount;
            currentYear.months += 1;
        }
    });
    (monthlydata.length >= 12
        ? monthlydata.slice(firstElement, firstElement + 12)
        : monthlydata).forEach((item) => {
            if(item.rentdata.surfacearea !== 0) {
                lastTwelveMonths.rent += item.rentdata.netrent + item.rentdata.servicecharges;
                lastTwelveMonths.turnover += parseFloat(item.turnover);
                lastTwelveMonths.sales += parseFloat(item.sales);
                lastTwelveMonths.surfacearea += item.rentdata.surfacearea;
                lastTwelveMonths.receipts += item.receiptcount;
            }
        });
    useEffect(() => {
        setUrl(`${process.env.REACT_APP_API}/performance/${store}`);
    }, [store, setUrl]);

    const prevMonth = () => {
        setFirstElement(firstElement - 1);
    };

    const nextMonth = () => {
        setFirstElement(firstElement + 1);
    };

    const SumRowTableCell = withStyles(theme => ({
        body: {
            fontStyle: 'italic',
            fontWeight: 'bold',
        }
    }))(TableCell);

    return (
        <Grid key={store} item sm={12}>
            <Toolbar disableGutters>
                <Typography variant='h5' style={{ flexGrow: 1 }}>
                    Tulostiedot
	            </Typography>
                <Button color='primary' variant='contained' disabled={firstElement === 0} onClick={() => prevMonth()}>
                    Seuraava kuukausi
                </Button>
                <Button color='primary' variant='contained' disabled={firstElement + rowsPerPage >= monthlydata.length} onClick={() => nextMonth()} style={{ margin: '0 1em' }}>
                    Edellinen kuukausi
                </Button>
            </Toolbar>
            <Paper elevation={1}>
                <Table id='ocrtable'>
                    <colgroup>
                        <col style={{ width: '20%' }} />
                        <col style={{ width: 'auto%' }} />
                        <col style={{ width: 'auto%' }} />
                        <col style={{ width: 'auto%' }} />
                        <col style={{ width: 'auto%' }} />
                        <col style={{ width: 'auto%' }} />
                        <col style={{ width: 'auto%' }} />
                        <col style={{ width: 'auto%' }} />
                    </colgroup>
                    <TableHead >
                        <TableRow >
                            <TableCell ></TableCell>
                            <TableCell align='center'>Kokonaisvuokra</TableCell>
                            <TableCell align='center'>Liikevaihto</TableCell>
                            <TableCell align='center'>OCR%</TableCell>
                            <TableCell align='center'>Myynti</TableCell>
                            <TableCell align='center'>Pinta-ala</TableCell>
                            <TableCell align='center'>Myyntiteho (€/m&sup2;/kk)</TableCell>
                            <TableCell align='center'>Keskiostos</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key='currentyear'>
                            <SumRowTableCell align='left' >Kuluva vuosi</SumRowTableCell>
                            <SumRowTableCell align='center' >{cformatter.format(currentYear.rent)}</SumRowTableCell>
                            <SumRowTableCell align='center' >{cformatter.format(currentYear.turnover)}</SumRowTableCell>
                            <SumRowTableCell align='center' >{fformatter.format((currentYear.rent / currentYear.turnover) * 100)}</SumRowTableCell>
                            <SumRowTableCell align='center' >{cformatter.format(currentYear.sales)}</SumRowTableCell>
                            <SumRowTableCell align='center' >{aformatter.format(currentYear.surfacearea / currentYear.months)} m&sup2;</SumRowTableCell>
                            <SumRowTableCell align='center' >{fformatter.format(currentYear.sales / currentYear.surfacearea)} €/m&sup2;</SumRowTableCell>
                            <SumRowTableCell align='center' >{cformatter.format(currentYear.sales / currentYear.receipts)}</SumRowTableCell>
                        </TableRow>
                        <TableRow key='twelvemonths'>
                            {monthlydata.length >= rowsPerPage
                                ? <SumRowTableCell align='left' >Edelliset 12 kuukautta</SumRowTableCell>
                                : <SumRowTableCell align='left' >Edelliset {monthlydata.length} kuukautta</SumRowTableCell>
                            }
                            <SumRowTableCell align='center' >{cformatter.format(lastTwelveMonths.rent)}</SumRowTableCell>
                            <SumRowTableCell align='center' >{cformatter.format(lastTwelveMonths.turnover)}</SumRowTableCell>
                            <SumRowTableCell align='center' >{fformatter.format((lastTwelveMonths.rent / lastTwelveMonths.turnover) * 100)}</SumRowTableCell>
                            <SumRowTableCell align='center' >{cformatter.format(lastTwelveMonths.sales)}</SumRowTableCell>
                            {monthlydata.length >= rowsPerPage
                                ? <SumRowTableCell align='center'>{aformatter.format(lastTwelveMonths.surfacearea / rowsPerPage)} m&sup2;</SumRowTableCell>
                                : <SumRowTableCell align='center' >{aformatter.format(lastTwelveMonths.surfacearea / currentYear.months)} m&sup2;</SumRowTableCell>
                            }
                            <SumRowTableCell align='center' >{fformatter.format(lastTwelveMonths.sales / lastTwelveMonths.surfacearea)} €/m&sup2;</SumRowTableCell>
                            <SumRowTableCell align='center' >{cformatter.format(lastTwelveMonths.sales / lastTwelveMonths.receipts)}</SumRowTableCell>
                        </TableRow>
                        {(monthlydata.length > rowsPerPage
                            ? monthlydata.slice(firstElement, firstElement + rowsPerPage)
                            : monthlydata
                        ).map((item) => {
                            return (
                                <TableRow key={item.id}>
                                    <TableCell align='left' >{months[parseInt(item.date.substr(5, 2) - 1)]} {item.date.substr(0, 4)}</TableCell>
                                    <TableCell align='center' >{cformatter.format(item.rentdata.netrent + item.rentdata.servicecharges)}</TableCell>
                                    <TableCell align='center' >{cformatter.format(item.turnover)}</TableCell>
                                    <TableCell align='center' >{fformatter.format(((item.rentdata.netrent + item.rentdata.servicecharges) / item.turnover) * 100)}</TableCell>
                                    <TableCell align='center' >{cformatter.format(item.sales)}</TableCell>
                                    <TableCell align='center' >{aformatter.format(item.rentdata.surfacearea)} m&sup2;</TableCell>
                                    <TableCell align='center' >{fformatter.format(item.sales / item.rentdata.surfacearea)} €/m&sup2;</TableCell>
                                    <TableCell align='center' >{cformatter.format(item.sales / item.receiptcount)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    );
}
export default ProfitInfo;