import React, { Component } from 'react';
import { fetch } from '@project/components';
import { withRouter } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import { userManager } from './store';

class CallbackPage extends Component {
	render() {
		return (
			<CallbackComponent
				userManager={userManager}
				successCallback={(user) => {
					fetch(`${process.env.REACT_APP_API}/login`, {
						method: 'POST',
						headers: {
							'Authorization': `Bearer ${user.id_token}`
						}
					})
					.then(response => {
						window.location.href = '/';
					});
				}}
				errorCallback={(error) => {
					console.error(error);
				}}
			>
				<div></div>
			</CallbackComponent>
		);
	}
}

export default withRouter(CallbackPage);
