import React, { useState } from 'react';
import InputFiles from 'react-input-files';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { showMessage, ConfirmDialog } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch } from '../common/functions';

const StoreFront = ({ tags, store, logo, photos, updateTaggable, updateStore }) => {
    const [{ data }] = useFetch(`${process.env.REACT_APP_API}/taggable/tags`);
    const [deletePhotoDialogOpen, setDeletePhotoDialogOpen] = useState(false);
    const [deletePhotoDialogItem, setDeletePhotoDialogItem] = useState(null);
    const [deleteLogoDialogOpen, setDeleteLogoDialogOpen] = useState(false);
    const allTags = data ? data.items : [];

    const toggleTag = (tag) => () => {
        if (tags.includes(tag)) {
            updateTaggable(tags.filter((id) => id !== tag));
        } else {
            updateTaggable([...tags, tag]);
        }
    };

    const uploadLogo = (files) => {
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);

        fetch(`${process.env.REACT_APP_API}/filestore/upload`, {
            method: 'POST',
            body: formData
        })
            .then((response) => response.item)
            .then((file) => {
                showMessage('Logo lisätty');
                updateStore({ logo: file ? file.id : null });
            })
            .catch(console.error);
    };

    const removeLogo = () => {
        updateStore({ logo: null });
    };

    const uploadPhotos = (files) => {
        const promises = [];

        Array.from(files).forEach((file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', file.name);

            promises.push(
                fetch(`${process.env.REACT_APP_API}/filestore/upload`, {
                    method: 'POST',
                    body: formData
                })
                    .then((response) => response.item)
                    .then((file) => {
                        if (!file) return;
                        fetch(`${process.env.REACT_APP_API}/store/${store.id}/photo/${file.id}`, { method: 'POST' })
                            .catch(console.error);
                    })
            );
        });

        Promise.all(promises).then((data) => {
            showMessage('Kuvat lisätty');
            updateStore({ id: store.id });
        });
    };

    const removePhoto = (file) => {
        fetch(`${process.env.REACT_APP_API}/store/${store.id}/photo/${file.id}`, { method: 'DELETE' })
            .then((response) => {
                showMessage('Kuva poistettu');
                updateStore({ id: store.id });
            })
            .catch(console.error);
    };

    const fileurl = (file) => {
        return `${process.env.REACT_APP_API}/filestore/download/inline/${file.id}/${file.name}`;
    };

    return (
		<>
			<Grid container spacing={2}>
			    <Grid item sm={12}>
			        <Toolbar disableGutters>
			            <Typography variant='h5'>
							Palvelut
			            </Typography>
			        </Toolbar>
			        <Paper elevation={1} style={{ padding: '1em 0.5em' }}>
			            {allTags.filter((tag) => tag.type === 'servicetag').map((tag) => (
			                <Chip clickable key={tag.id}
			                    label={tag.name}
			                    color={tags.find((item) => item === tag.id) ? 'secondary' : 'default'}
			                    onClick={toggleTag(tag.id)}
			                    style={{ margin: '0.5em' }}
			                />
			            ))}
			        </Paper>
			        <Toolbar disableGutters>
			            <Typography variant='h5'>
							Raportointialueet
			            </Typography>
			        </Toolbar>
			        <Paper elevation={1} style={{ padding: '1em 0.5em' }}>
			            {allTags.filter((tag) => tag.type === 'reportingtag').map((tag) => (
			                <Chip clickable key={tag.id}
			                    label={tag.name}
			                    color={tags.find((item) => item === tag.id) ? 'secondary' : 'default'}
			                    onClick={toggleTag(tag.id)}
			                    style={{ margin: '0.5em' }}
			                />
			            ))}
			        </Paper>
			        <Toolbar disableGutters>
			            <Typography variant='h5'>
							Logo
			            </Typography>
			        </Toolbar>
			        <Paper elevation={1} style={{ padding: '1em' }}>
			            <Grid container direction='column'>
			                <Grid container item justify='space-around'>
			                    <InputFiles onChange={uploadLogo} accept={'image/*'}>
			                        <Button variant='contained' color='primary'>{logo ? 'Vaihda logo' : 'Lisää logo'}</Button>
			                    </InputFiles>
			                    <Button
			                        variant='contained'
			                        color='primary'
			                        onClick={() => setDeleteLogoDialogOpen(true)}
			                        disabled={!logo}
			                    >
										Poista logo
			                    </Button>
			                </Grid>
			                <Grid container item alignItems='center' justify='center'>
			                    {logo && <img src={fileurl(logo)} alt='Liikkeen logo' width='200'/>}
			                </Grid>
			            </Grid>
			        </Paper>
			        <Toolbar disableGutters>
			            <Typography variant='h5'>
							Yleiskuvat
			            </Typography>
			        </Toolbar>
			        <Paper elevation={1} style={{ padding: '1em' }}>
			            <InputFiles onChange={uploadPhotos} accept={'image/*'} multiple={true}>
			                <Button variant='contained' color='primary'>Lisää yleiskuva</Button>
			            </InputFiles>
			            <Grid container>
			                {photos.map((photo) => (
			                    <Grid item xs key={photo.id} container direction='column'>
			                        <Grid item xs>
			                            <img src={fileurl(photo)} alt='Yleiskuva liikkeestä' width='200'/>
			                        </Grid>
			                        <Grid item xs>
			                            <Button
			                                variant='contained'
			                                color='primary'
			                                onClick={() => {
			                                    setDeletePhotoDialogItem(photo);
			                                    setDeletePhotoDialogOpen(true);
			                                }}
			                            >
											Poista
			                            </Button>
			                        </Grid>
			                    </Grid>
			                ))}
			            </Grid>
			        </Paper>
			    </Grid>
			</Grid>
			<ConfirmDialog
			    open={deleteLogoDialogOpen}
			    title='Poista logo'
			    text='Haluatko varmasti poistaa logon?'
			    confirmText='Poista'
			    onClose={() => setDeleteLogoDialogOpen(false)}
			    onConfirm={() => {
			        setDeleteLogoDialogOpen(false);
			        removeLogo();
			    }}
			/>
			<ConfirmDialog
			    open={deletePhotoDialogOpen}
			    title='Poista kuva'
			    text='Haluatko varmasti poistaa kuvan?'
			    confirmText='Poista'
			    onClose={() => setDeletePhotoDialogOpen(false)}
			    onConfirm={() => {
			        console.log('confirm');
			        setDeletePhotoDialogOpen(false);
			        removePhoto(deletePhotoDialogItem);
			    }}
			/>
		</>
    );
};

export default StoreFront;
