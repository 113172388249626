import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useFetch } from '../common/hooks';
import { stableSort } from '../common/functions';
import StoreMonthReport from './StoreMonthReport';
import { months } from '../constants';
import moment from 'moment';
import { TableFooter } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const cformatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' });
const pformatter = new Intl.NumberFormat('fi-FI', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 });

const Reporting = ({ mall }) => {
    const [month, setMonth] = useState(moment().subtract(moment().date() > 10 ? 1 : 2, 'M').format('YYYY-MM'));
    const [{ data }] = useFetch(`${process.env.REACT_APP_API}/mall/${mall.id}/search`);
    const stores = data ? data.results : [];
    const [{ data: salesdata }] = useFetch(`${process.env.REACT_APP_API}/mall/${mall.id}/sales`);
    const saleslist = salesdata ? salesdata.items : [];
    const monthlydata = {
        receipts: 0,
        sales: 0.0,
        prevYearMonthlyreceipts: 0,
        prevYearMonthlysales: 0.0,
        currentyear: 0.0,
        previousyear: 0.0,
    };
    saleslist.forEach((store) => {
        store.sales.forEach((item) =>{
            if(moment(item.date).format('YYYY-MM') === month){
                monthlydata.receipts += item.receipts;
                monthlydata.sales += parseFloat(item.sales);
            }
            if(moment(item.date).add(12, 'months').format('YYYY-MM') === month){
                console.log('item.date: ' + moment(item.date).format('YYYY-MM') + ', month: ' + month);
                monthlydata.prevYearMonthlyreceipts += item.receipts;
                monthlydata.prevYearMonthlysales += parseFloat(item.sales);
            }
            if (moment(item.date).format('MM') <= month.split('-')[1]) {
                if(moment(item.date).year() === moment(`${month}-01`).year()){
                    monthlydata.currentyear += parseFloat(item.sales);
                }
                if(moment(item.date).add(1, 'years').year() === moment(`${month}-01`).year()){
                    monthlydata.previousyear += parseFloat(item.sales);
                }
            }
        });
    });

    const prevMonth = () => {
        setMonth(moment(`${month}-01`).subtract(1, 'M').format('YYYY-MM'));
    };

    const nextMonth = () => {
        setMonth(moment(`${month}-01`).add(1, 'M').format('YYYY-MM'));
    };

    const downloadAsCSV = () => {
        let BOM = '\ufeff';
        let table = document.getElementById('reportingtable');
        let header = Array.from(table.querySelectorAll('thead th')).map((th) => th.textContent);
        let data = Array.from(table.querySelectorAll('tbody tr'))
            .filter((tr) => tr.childElementCount > 2)
            .map((tr) => Array.from(tr.querySelectorAll('td')).map((td) => td.textContent));
        let csv = BOM + [header, ...data].map((row) => row.join('\t')).join('\n').replace(/\u00A0/g, ' ');
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csv));
        downloadAnchorNode.setAttribute('download', `raportit-${month}.csv`);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    const SumRowTableCell = withStyles((theme) => ({
        footer: {
            fontStyle: 'italic',
            fontWeight: 'bold',
        }
    }))(TableCell);
    console.log('monthlyDataSales: ' + monthlydata.sales + ', prev: ' + monthlydata.prevYearMonthlysales);
    return (
        <Container maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={10} xl={8}>
                    <Typography variant='h5'>
                        Kuukausiraportointi {month.substr(0, 4)} {months[parseInt(month.substr(5, 2) - 1)].toLowerCase()}
                    </Typography>
                    <Toolbar disableGutters>
                        <Button color='primary' variant='contained' onClick={() => prevMonth()}>Edellinen kuukausi</Button>
                        <Button color='primary' variant='contained' onClick={() => nextMonth()} style={{ margin: '0 1em' }}>Seuraava kuukausi</Button>
                        <Button color='primary' variant='contained' onClick={() => downloadAsCSV()}>Lataa CSV</Button>
                    </Toolbar>
                    <Paper elevation={1} style={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                        <Table id='reportingtable' stickyHeader>
                            <colgroup>
                                <col style={{ width: 'auto' }} />
                                <col style={{ width: '70px' }} />
                                <col style={{ width: '70px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '70px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '70px' }} />
                                <col style={{ width: '40px' }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Liike</TableCell>
                                    <TableCell padding='none' align='right'>Kuitit</TableCell>
                                    <TableCell padding='none' align='center'>Δ</TableCell>
                                    <TableCell padding='none' align='right'>Myynti</TableCell>
                                    <TableCell padding='none' align='center'>Δ</TableCell>
                                    <TableCell padding='none' align='right'>Vuoden alusta</TableCell>
                                    <TableCell padding='none' align='center'>Δ</TableCell>
                                    <TableCell padding='none'></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(stores, 'label').map((store) => (
                                    <TableRow key={store.value}>
                                        <TableCell>{store.label}</TableCell>
                                        <StoreMonthReport store={store.value} month={month} />
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <SumRowTableCell>Yhteensä</SumRowTableCell>
                                    <SumRowTableCell padding='none' align='right'>{monthlydata.receipts}</SumRowTableCell>
                                    <SumRowTableCell>{pformatter.format((monthlydata.receipts - monthlydata.prevYearMonthlyreceipts) / monthlydata.prevYearMonthlyreceipts)}</SumRowTableCell>
                                    <SumRowTableCell padding='none' align='right'>{cformatter.format(monthlydata.sales)}</SumRowTableCell>
                                    <SumRowTableCell>{pformatter.format((monthlydata.sales - monthlydata.prevYearMonthlysales) / monthlydata.prevYearMonthlysales)}</SumRowTableCell>
                                    <SumRowTableCell padding='none' align='right'>{cformatter.format(monthlydata.currentyear)}</SumRowTableCell>
                                    <SumRowTableCell>{pformatter.format((monthlydata.currentyear - monthlydata.previousyear) / monthlydata.previousyear)}</SumRowTableCell>
                                    <SumRowTableCell></SumRowTableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Reporting;
