import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { fetchWrap as fetch } from '../common/functions';
import { showMessage } from '../common';

const EditUserDialog = ({ open, user, onClose, onSave }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    useEffect(() => {
        if (open && user) {
            setName(user.name || '');
            setEmail(user.email || '');
            setPhone(user.phone || '');
        }
    }, [open, user]);

    const updateUser = () => {
        fetch(`${process.env.REACT_APP_API}/users/user/${user.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                user: {
                    name,
                    email,
                    phone
                }
            })
        })
            .then((response) => {
                onSave();
                showMessage('Käyttäjä päivitetty');
            })
            .catch(console.error);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>
				Muokkaa käyttäjää
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    type='text'
                    id='editUserDialogName'
                    name='editUserDialogName'
                    label='Nimi'
                    fullWidth
                    variant='filled'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    margin='normal'
                />
                <TextField
                    required
                    disabled
                    type='email'
                    id='editUserDialogEmail'
                    name='editUserDialogEmail'
                    label='Sähköposti'
                    fullWidth
                    variant='filled'
                    value={email}
                    margin='normal'
                />
                <TextField
                    type='tel'
                    id='editUserDialogPhone'
                    name='editUserDialogPhone'
                    label='Puhelin'
                    fullWidth
                    variant='filled'
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    margin='normal'
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' onClick={updateUser}>
                    Päivitä
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserDialog;
