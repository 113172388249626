import React from 'react';
import Filesize from 'filesize';
import { withTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import { showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch } from '../common/functions';

const ResourceIcon = ({ theme, resource }) => {
    if (resource.type === 'externallink') {
        return <LinkIcon style={theme.resourceIconColor}/>;
    }
    if (resource.mime && resource.mime.startsWith('image/')) {
        return <ImageIcon style={theme.resourceIconColor}/>;
    }
    return <FileIcon style={theme.resourceIconColor}/>;
};

const FileList = ({ theme, owner }) => {
    const [{ data, refresh }] = useFetch(`${process.env.REACT_APP_API}/resourceowner/${owner}`);
    const items = data ? data.items : [];
    const resources = data ? data.resources : [];
    const read = data ? data.read : [];

    const markAsRead = (confirmable, refresh) => () => {
        fetch(`${process.env.REACT_APP_API}/readconfirmable/${confirmable}/confirm`, {
            method: 'POST'
        })
            .then((response) => {
                showMessage('Tiedostot kuitattu');
                refresh();
            })
            .catch((error) => {
                showMessage('Tiedostojen kuittaus epäonnistui', true);
                console.error(error);
            });
    };

    const linkStyle = theme.linkColor;
    const resourceFilter = (item) => resources.includes(item.id) && item.type === 'staticresourcelist' && item.behaviors.resourceowner.resources.length > 0;

    return items.filter(resourceFilter).map((resourcelist) => (
        <div key={resourcelist.id}>
            <Toolbar disableGutters>
                <Typography variant='h6'>
                    {resourcelist.title || <small>&lt;nimetön&gt;</small>}
                </Typography>
            </Toolbar>
            <Paper style={{ padding: '1em', overflow: 'auto' }}>
                <List dense={true}>
                    {items.filter((item) => resourcelist.behaviors.resourceowner.resources.includes(item.id)).map((resource) => (
                        <ListItem key={resource.id}>
                            <ListItemIcon>
                                <ResourceIcon theme={theme} resource={resource}/>
                            </ListItemIcon>

                            {resource.type === 'externallink' && (
                                <ListItemText
                                    primary={
                                        <a style={linkStyle} href={resource.url} target={resource.id}>
                                            {resource.title}
                                        </a>
                                    }
                                    secondary={resource.url}
                                />
                            )}
                            {resource.type === 'file' && (
                                <ListItemText
                                    primary={
                                        <a style={linkStyle} href={`${process.env.REACT_APP_API}/filestore/download/inline/${resource.id}/${resource.name}`} target={resource.id}>
                                            {resource.name}
                                        </a>
                                    }
                                    secondary={`${Filesize(resource.size)} (${resource.mime})`}
                                />
                            )}
                        </ListItem>
                    ))}
                </List>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={read.includes(resourcelist.id)}
                    onClick={markAsRead(resourcelist.id, refresh)}
                >
                    {read.includes(resourcelist.id) ? 'Kuitattu luetuksi' : 'Kuittaa luetuksi'}
                </Button>
            </Paper>
        </div>
    ));
};

export default withTheme(FileList);
