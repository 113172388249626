import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';

export default function EditRentDialog({ open, store, row, onSave, onClose }) {
    const [id, setId] = useState(null);
    const [startdate, setStartdate] = useState(`${new Date().toISOString().substr(0, 7)}-01`);
    const [enddate, setEnddate] = useState('');
    const [contractenddate, setContractenddate] = useState('');
    const [notice, setNotice] = useState('');
    const [netrent, setNetrent] = useState('');
    const [servicecharges, setServicecharges] = useState('');
    const [surfacearea, setSurfacearea] = useState('');
    const [info, setInfo] = useState('');
    const [isfixedperiod, setIsfixedperiod] = useState(false);
    useEffect(() => {
        if (open) {
            setId(row.id);
            setStartdate(row.startdate);
            setEnddate(row.enddate ? row.enddate : '');
            setContractenddate(row.contractenddate ? row.contractenddate : '');
            setNotice(row.notice ? row.notice.toString() : '');
            setNetrent(row.netrent.toString());
            setServicecharges(row.servicecharges.toString());
            setSurfacearea(row.surfacearea.toString());
            setInfo(row.info ? row.info : '');
            row.contractenddate ? setIsfixedperiod(true) : setIsfixedperiod(false);
        }
    }, [open, row]);

    const parseNumber = (number) => {
        // Remove non numbers
        let result = number.trim().replace(/[^0-9]/g, '');
        // Ends with . or , and 1 number
        if (/[,.]\d{1}$/.test(number)) {
            result = result.replace(/(\d{1})$/, '.$1');
        }
        // Ends with . or , and 2 numbers
        if (/[,.]\d{2}$/.test(number)) {
            result = result.replace(/(\d{2})$/, '.$1');
        }
        return parseFloat(result);
    };

    const updateRentInfo = (store, row) => {
        fetch(`${process.env.REACT_APP_API}/rent/${store}/row/${row}`, {
            method: 'PUT',
            body: JSON.stringify({
                rentdata: {
                    startdate: moment(startdate).format('YYYY-MM-DD'),
                    enddate: enddate ? moment(enddate).format('YYYY-MM-DD') : null,
                    contractenddate: contractenddate ? moment(contractenddate).format('YYYY-MM-DD') : null,
                    notice,
                    netrent: parseNumber(netrent),
                    servicecharges: parseNumber(servicecharges),
                    surfacearea: parseNumber(surfacearea),
                    info
                }
            })
        })
            .then((response) => {
                showMessage('Vuokratiedot päivitetty');
                onSave();
            })
            .catch(console.error);
    };

    const toggle = () => {
        if (isfixedperiod) {
            setIsfixedperiod(false);
        } else {
            setIsfixedperiod(true);
        }
    };

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Vuokratiedot</DialogTitle>
                <DialogContent>
                    <input id='isfixedperiod' type='checkbox' onClick={() => toggle()} defaultChecked={contractenddate ? true : false} />
                    <label htmlFor="isfixedperiod">Määräaikainen</label>
                    <KeyboardDatePicker
                        required
                        id='startdate'
                        name='startdate'
                        label='Alkamispäivä'
                        clearable
                        value={startdate}
                        onChange={date => setStartdate(date)}
                        format='DD/MM/YYYY'
                        fullWidth
                        inputVariant='filled'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                    />
                    <KeyboardDatePicker
                        id='enddate'
                        name='enddate'
                        label='Päättymispäivä'
                        clearable
                        value={enddate || null}
                        onChange={date => setEnddate(date)}
                        format='DD/MM/YYYY'
                        fullWidth
                        inputVariant='filled'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                    />
                    {isfixedperiod && <KeyboardDatePicker
                        required={isfixedperiod}
                        id='contractenddate'
                        name='contractenddate'
                        label='Sopimuksen päättymispäivä'
                        clearable
                        value={contractenddate || null}
                        onChange={date => setContractenddate(date)}
                        format='DD/MM/YYYY'
                        fullWidth
                        inputVariant='filled'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                    />}
                    {!isfixedperiod && <TextField
                        type='text'
                        id='notice'
                        name='notice'
                        label='Irtisanomisaika(kk)'
                        fullWidth
                        variant='filled'
                        value={notice}
                        margin='normal'
                        onChange={(event) => setNotice(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />}

                    <TextField
                        required
                        type='text'
                        id='netrent'
                        name='netrent'
                        label='Pääomavuokra'
                        fullWidth
                        variant='filled'
                        value={netrent}
                        margin='normal'
                        onChange={(event) => setNetrent(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        required
                        type='text'
                        id='servicecharges'
                        name='servicecharges'
                        label='Ylläpitovuokra'
                        fullWidth
                        variant='filled'
                        value={servicecharges}
                        margin='normal'
                        onChange={(event) => setServicecharges(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        required
                        type='text'
                        id='surfacearea'
                        name='surfacearea'
                        label='Pinta-ala'
                        fullWidth
                        variant='filled'
                        value={surfacearea}
                        margin='normal'
                        onChange={(event) => setSurfacearea(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        type='text'
                        id='info'
                        label='Info'
                        variant='filled'
                        multiline
                        rows={4}
                        margin='normal'
                        fullWidth
                        value={info}
                        onChange={(event) => setInfo(event.target.value)}
                        inputProps={{ maxLength: 160 }}
                        InputLabelProps={{ shrink: true }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Peruuta
                     </Button>
                    <Button onClick={() => updateRentInfo(store, id)} color="primary">
                        Tallenna
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
