import React from "react";

const months = [
    'Tammikuu',
    'Helmikuu',
    'Maaliskuu',
    'Huhtikuu',
    'Toukokuu',
    'Kesäkuu',
    'Heinäkuu',
    'Elokuu',
    'Syyskuu',
    'Lokakuu',
    'Marraskuu',
    'Joulukuu'
];

const monthOptions = [
    { value: 0, label: 'Tammikuu' },
    { value: 1, label: 'Helmikuu' },
    { value: 2, label: 'Maaliskuu' },
    { value: 3, label: 'Huhtikuu' },
    { value: 4, label: 'Toukokuu' },
    { value: 5, label: 'Kesäkuu' },
    { value: 6, label: 'Heinäkuu' },
    { value: 7, label: 'Elokuu' },
    { value: 8, label: 'Syyskuu' },
    { value: 9, label: 'Lokakuu' },
    { value: 10, label: 'Marraskuu' },
    { value: 11, label: 'Joulukuu' }
];

const getOrderedResourceLists = () => {
    const app = process.env.REACT_APP_NAME;
    switch (app) {
        case 'martinlaakso':
            return ['Yhteystiedot', 'Markkinointi ja tapahtumat', 'Jätteiden lajittelu', 'Kiinteistön tiedotteet', 'Kävijätilastot', 'Vuokralaistapaamisten esitykset', 'Led-näytöt ja promootiot', 'Pelastussuunnitelma', 'Muut tiedotteet'];
        case 'grani':
            return ['Yhteystiedot', 'Markkinointi ja tapahtumat', 'Jätteiden lajittelu', 'Kiinteistön tiedotteet', 'Kävijätilastot', 'Vuokralaistapaamisten esitykset', 'Led-näytöt ja promootiot', 'Pelastussuunnitelma', 'Muut tiedotteet'];
        default:
            return [];
    }
}

export { months, monthOptions, getOrderedResourceLists };
