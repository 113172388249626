import { createMuiTheme } from '@material-ui/core/styles';

import Background from './images/background.svg';
import Logo from './images/logo.png';

export { Logo };

const custom = {
	primary: '#283583',
	secondary: '#4153b5',
	tertiary: '#b7e7f1',
	subPrimary: '#f48d14',
	subSecondary: '#f8cf76',
	subTertiary: '#6e3c01',
	general: '#f8cf76',
	iconColor: '#6e3c01',
	listIconColor: '#b7e7f1',
	resourceIconColor: '#6e3c01',
	linkColor: '#4153b5',
	loginViewBackground: '#283583',
	loginPaperColor: '#4153b5',
	mainBackground: '#F6DAA1',
	dialogActionsBase: '#6e3c01',
	chipColor: '#6e3c01',
	chipBackground: '#6e3c01',
	drawerBackground: '#283583',
	drawerText: {
		fontSize: '16px',
		fontWeight: 'bold',
		color: '#b7e7f1',
	},
};

const theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: custom.primary,
		},
		secondary: {
			main: custom.subPrimary,
			dark: custom.subPrimary,
		},
		background: {
			paper: custom.subSecondary,
			default: custom.general,
		},
		error: {
			main: '#FF0000',
		},
	},
	typography: {
		useNextVariants: true,
		// Use the system font.
		fontFamily:
			'-apple-system,system-ui,BlinkMacSystemFont,' +
			'"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
		body2: {
			color: custom.subTertiary
		},
		body1: {
			color: custom.subTertiary
		},
		h3: {
			color: custom.primary
		},
		h4: {
			color: custom.primary
		},
		h5: {
			margin: '0.5em 0',
			fontWeight: 'bold',
			color: custom.primary
		},
		h6: {
			color: custom.primary
		}
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				body: {
					backgroundColor: custom.mainBackground,
					height: '100vh',
					backgroundImage: 'url(' + Background + ')',
					backgroundSize: '30% 30%',
					backgroundPosition: '280px 90%',
					backgroundRepeat: 'no-repeat',
					backgroundBlendMode: 'normal',
				},
			},
		},
		MuiPaper: {
			root: {
				color: custom.subTertiary,
			}
		},
		MuiDrawer: {
			paper: {
				background: custom.drawerBackground
			}
		},
		MuiIconButton: {
			root: {
				color: custom.subTertiary
			}
		},
		MuiChip: {
			root: {
				color: custom.chipColor,
				backgroundColor: custom.chipBackground + '4d',
			},
			label: {
				color: custom.subTertiary,
				'&:hover': {
					color: custom.subTertiary
				},
			},
			clickable:{
				'&:hover': {
					backgroundColor: custom.tertiary
				},
				'&:focus': {
					backgroundColor: custom.chipBackground + '4d',
					'&:hover': {
						backgroundColor: custom.tertiary,
					},
				},
			},
			clickableColorSecondary: {
				'&:hover': {
					backgroundColor: custom.tertiary
				},
			},

		},
		MuiFormLabel: {
			root: {
				'&$disabled': {
					color: custom.subTertiary,
				}
			},
		},
		MuiInputLabel: {
			root: {
				color: custom.subTertiary,
			},
			shrink: {
				transform: 'translate(0, 0)',
				textTransform: 'capitalize',
			},
		},
		MuiInputBase: {
			input: {
				color: custom.subTertiary,
				position: 'relative',
				background: custom.subPrimary + '4d',
				border: '1px inset rgba(0,41,77, 0.3)',
				borderRadius: '3px',
			},
			multiline: {
				padding: '0',
			},
		},
		MuiNativeSelect: {
			icon: {
				color: custom.subTertiary,
			},
			select: {
				width: 'calc(100% - 20px)',
				paddingLeft: '5px',
				paddingRight: '20px',
				'&:focus':{
					color: '#111',
					backgroundColor: custom.subPrimary + 'fd',
				},
			},
		},
		MuiTableCell: {
			head: {
				fontSize: '13px',
				color: custom.subTertiary,
			},
			body: {
				color: custom.subTertiary,
			},
			root: {
				borderBottom: '1px solid #003150',
			},
		},
		MuiTabs: {
			root: {
				background: custom.secondary,
				color: custom.tertiary,
			},
			indicator: {
				background: custom.subSecondary,
				height: '5px',
			},
		},
		MuiTab: {
			root: {
				"&:hover":{
					color: '#002039',
					opacity: '1',
					background: custom.subSecondary,
				},
				color: '#002039',
				"&$selected":{
					color: '#002039',
					background: custom.subPrimary,
				}
			},
			textColorPrimary: {
				color: custom.tertiary
			},
			fullWidth: {
				fontSize: '15px',
			}
		},
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: custom.subTertiary,
			}
		},
		MuiPickersDay: {
			day: {
				color: custom.subTertiary,
			},
			daySelected: {
				color: custom.secondary,
				backgroundColor: custom.tertiary
			}
		},
		MuiInputAdornment: {
			positionEnd: {
				color: custom.subTertiary,
				fontSize: '13px',
				fontWeight: 'bold'
			}
		},
		MuiSelect: {
			icon: {
				color: custom.iconColor,
			}
		},
		MuiListItemIcon: {
			root: {
				color: custom.listIconColor
			}
		},
		MuiListItemText: {
			primary: {
				color: custom.tertiary
			},
			secondary: {
				color: custom.subTertiary,
			}
		},
	},
	deleteIcon: {
		color: custom.iconColor,
		height: '28px',
		width: '28px',
	},
	checkBox: {
		alignSelf: 'flex-end',
		color: custom.subTertiary,
	},
	adminRoot: {
		display: 'flex',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	logoSlot: {
		background: '#f1f1f1',
		padding: '5px'
	},
	//Login component START
	loginRoot: {
		display: 'flex',
		height: '100vh',
		// backgroundColor: custom.loginViewBackground,
		// backgroundImage: 'url(' + Background + ')',
		// backgroundSize: 'cover',
		// backgroundPosition: 'center',
	},
	loginPaper: {
		padding: '3em',
		marginTop: '-30vh',
		background: custom.loginPaperColor,
	},
	//Login component END
	asyncSelect: {
		color: custom.subTertiary,
		focused: {
			color: custom.tertiary,
		},
		selected: {
			color: custom.subPrimary,
		}
	},
	resourceIconColor:{
		color: custom.resourceIconColor,
	},
	linkColor: {
		color: custom.linkColor,
	},
	drawerText: custom.drawerText,
});

export default theme;
