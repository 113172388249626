import React from 'react';
import { fetch } from '@project/components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import NativeSelect from '@material-ui/core/NativeSelect';
import PersonIcon from '@material-ui/icons/Person';

import { Store, stableSort, Rent } from '@project/components';

const styles = (theme) => ({
	page: {
		width: '100%',
	},
	title: {
		flexGrow: 1,
		textAlign: 'right',
	},
	content: {
		display: 'block',
		flex: 1,
		flexGrow: 1,
		overflow: 'auto',
	}
});

class DashboardPage extends React.Component {

	state = {
		menu: [],
		store: undefined,
		active: undefined,
	}

	componentDidMount() {
		// preselecting first result
		if (this.props.stores.length > 0) {
			this.setState({ store: this.props.stores[0] });
		}

		this.props.contexts.forEach((mall) => {
			fetch(`${process.env.REACT_APP_API}/mall/${mall}/search?term=`)
				.then((response) => this.setState(
					(prevState) => ({
						menu: stableSort(prevState.menu.concat(response.results), 'label')
					}),
					this.selectFirstIfNone
				))
				.catch(console.error)
		})
	}

	selectFirstIfNone = () => {
		if (this.state.store === undefined && this.state.menu.length > 0) {
			this.setState({
				store: this.state.menu[0].value,
				active: this.state.menu[0].active,
			});
		}
	}

	handleStoreChange = (event) => {
		this.setState({
			store: event.target.value,
			active: (this.state.menu.find(store => store.value === event.target.value)).active,
		});
	}

	getDashboardContent = () => {
		return this.props.dpcontent === 'Rent'
			? <Rent store={this.state.store} active={this.state.active} changeActivity={this.handleActivityChange}/>
			: <Store store={this.state.store} />;
	}

	handleActivityChange = (active) => {
		this.setState({ menu: [], active: active });
		this.props.contexts.forEach((mall) => {
			fetch(`${process.env.REACT_APP_API}/mall/${mall}/search?term=`)
				.then((response) => this.setState(
					(prevState) => ({
						menu: stableSort(prevState.menu.concat(response.results), 'label')
					}),
					this.selectFirstIfNone
				))
				.catch(console.error)
		})
	}

	render() {
		const { classes, stores, user } = this.props;
		const { menu, store } = this.state;

		return (
			<div className={classes.page}>
				<AppBar position='sticky' color='inherit'>
					<Toolbar>
						<NativeSelect
							value={store}
							onChange={this.handleStoreChange}
							name='store'
						>
							{!store && <option></option>}
							{menu.filter(item => stores.length === 0 || stores.includes(item.value)).sort((a,b) => b.active - a.active).map(item => (
								<option key={item.value} value={item.value}>{item.active ? item.label : item.label + '*'}</option>
							))}
						</NativeSelect>
						{user && (
							<>
								<Typography component='h3' variant='subtitle1' noWrap className={classes.title}>
									{user.name}
								</Typography>
								<PersonIcon style={{marginLeft: '1em'}} color='action' />
								<Button variant='text' color='secondary' style={{marginLeft: '1em'}} onClick={() => window.location.href = '/logout'}>
									Kirjaudu ulos
								</Button>
							</>
						)}
					</Toolbar>
				</AppBar>
				<main className={classes.content}>
					{store && this.getDashboardContent()}
				</main>
			</div>
		);
	}
}

DashboardPage.propTypes = {
	stores: PropTypes.arrayOf(PropTypes.string).isRequired,
	contexts: PropTypes.arrayOf(PropTypes.string).isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardPage);
