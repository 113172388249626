import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useFetch } from '../common/hooks';
import WarningIcon from '@material-ui/icons/Warning';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';

export default function EmergencyMessageDialog() {
    const [open, setOpen] = React.useState(false);
    const [{ data: userData }] = useFetch(`${process.env.REACT_APP_API}/users/list`);
    const users = userData ? userData.items : [];
    const roles = userData ? userData.roles : [];
    const [selectedRoles, setSelectedRoles] = React.useState([]);
    const [recipients, setRecipients] = useState([]);
    const [body, setBody] = useState('');

    useEffect(() => {
        setRecipients(users.filter((item) => item.behaviors.privileged.contexts.some((ctx) => selectedRoles.includes(ctx.role))));
    }, [selectedRoles, users]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRoles([]);
        setBody('');
    };

    const sendSMS = () => {
        const phoneNums = [...new Set(recipients
            .filter((user) => user.phone)
            .map((user) => user.phone))];

        fetch(`${process.env.REACT_APP_API}/sms/send`, {
            method: 'POST',
            body: JSON.stringify({
                message: body,
                numbers: phoneNums
            })
        })
            .then((response) => {
                setOpen(false);
                setSelectedRoles([]);
                setBody('');
                showMessage('Viesti lähetetty');
            })
            .catch(console.error);
    };

    return (
        <div>
            <Button variant="contained" color="secondary" startIcon={<WarningIcon />} onClick={handleClickOpen}>
                Lähetä Hätäviesti
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Hätäviesti</DialogTitle>
                <DialogContent>
                    <DialogContentText>Vastaanottajaryhmät
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ marginLeft: '1em', flexShrink: 0 }}
                            onClick={() => setSelectedRoles(roles.map((item) => item.id))}
                        >
						    Kaikki
                        </Button>
                    </DialogContentText>
                    <Select
                        multiple
                        value=  {selectedRoles}
                        onChange={(event) => setSelectedRoles(event.target.value)}
                        fullWidth
                    >
                        {roles.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                                {role.name}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>Viesti</DialogContentText>
                    <TextField
                        autoFocus
                        multiline
                        maxLength='140'
                        rows={4}
                        id="name"
                        label="Viesti"
                        type="text"
                        fullWidth
                        value={body}
                        onChange={(event) => setBody(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Peruuta
                    </Button>
                    <Button onClick={sendSMS} color="primary">
                        Lähetä
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
