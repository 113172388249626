import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Links from './Links';

const languages = {
    fi: 'Suomi',
    sv: 'Svenska',
};

class StoreInfo extends Component {

	state = {
	    frozen: this.props.presentations.reduce((o, item) => Object.assign(o, { [item.id]: JSON.stringify(item) }), {}),
	}

	updateField(object, field, value) {
	    // trususting shallow copy
	    object[field] = value;
	    this.setState(this.state);
	}

	updateItem = (object, field) => (event) => {
	    this.updateField(object, field, event.target.value);
	}

	updateLinks = (object) => (links) => {
	    this.updateField(object.behaviors.socialmedia, 'links', links);
	}

	freezePresentations = () => {
	    this.setState((prevState) => ({
	        frozen: this.props.presentations.reduce((o, item) => Object.assign(o, { [item.id]: JSON.stringify(item) }), {}),
	    }));
	}

	render() {
	    const languageSort = (a, b) => a.behaviors.presentation.language > b.behaviors.presentation.language;

	    return (
	        <Grid container spacing={2}>
	            {this.props.presentations.sort(languageSort).map((presentation) => {
	                const somelinks = presentation.behaviors.socialmedia.links;
	                const hasChanged = this.state.frozen[presentation.id] !== JSON.stringify(presentation);

	                return (
	                    <Grid key={presentation.id} item sm={12}>
	                        <Toolbar disableGutters>
	                            <Typography variant='h5' style={{ flexGrow: 1 }}>
	                                {languages[presentation.behaviors.presentation.language]}
	                            </Typography>
	                            <Button
	                                variant='contained'
	                                color='primary'
	                                onClick={() => this.props.updateStore({ presentations: [presentation] }, this.freezePresentations)}
	                                disabled={!hasChanged}
	                            >
									Tallenna
	                            </Button>
	                        </Toolbar>
	                        <Paper style={{ padding: '1em' }}>
	                            <Grid container item spacing={2}>
	                                <Grid item xs={12} lg={6}>
	                                    <TextField
	                                        name='name'
	                                        label='Nimi'
	                                        value={presentation.name}
	                                        onChange={this.updateItem(presentation, 'name')}
	                                        InputLabelProps={{ shrink: true }}
	                                        margin='normal'
	                                        fullWidth
	                                    />
	                                    <TextField
	                                        name='phone'
	                                        label='Puhelin'
	                                        value={presentation.phone}
	                                        onChange={this.updateItem(presentation, 'phone')}
	                                        InputLabelProps={{ shrink: true }}
	                                        margin='normal'
	                                        fullWidth
	                                    />
	                                    <TextField
	                                        name='email'
	                                        label='Sähköposti'
	                                        value={presentation.email}
	                                        onChange={this.updateItem(presentation, 'email')}
	                                        InputLabelProps={{ shrink: true }}
	                                        margin='normal'
	                                        fullWidth
	                                    />
	                                    <TextField
	                                        name='description'
	                                        label='Kuvaus'
	                                        value={presentation.description}
	                                        onChange={this.updateItem(presentation, 'description')}
	                                        InputLabelProps={{ shrink: true }}
	                                        margin='normal'
	                                        fullWidth
	                                        multiline
	                                        rows='10'
	                                    />
	                                    <TextField
	                                        name='location'
	                                        label='Sijainti liikekeskuksessa'
	                                        value={presentation.location}
	                                        onChange={this.updateItem(presentation, 'location')}
	                                        InputLabelProps={{ shrink: true }}
	                                        margin='normal'
	                                        fullWidth
	                                    />
	                                </Grid>
	                                <Grid item xs={12} lg={6}>
	                                    <Links updateLinks={this.updateLinks(presentation)} someLinks={somelinks} />
	                                </Grid>
	                            </Grid>
	                        </Paper>
	                    </Grid>
	                );
	            })}
	        </Grid>
	    );
	}
};

export default StoreInfo;
