import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import { fetchWrap as fetch } from '../common/functions';
import { useFetch } from '../common/hooks';

import FileList from './FileList';
import Hours from '../general/Hours';
import StoreFront from './StoreFront';
import StoreInfo from './StoreInfo';
import StoreReports from './StoreReports';


const Store = ({ store }) => {
    const [{ data, refresh }, setUrl] = useFetch(null);
    const storeData = data ? data.items : [];
    const [openTab, setOpenTab] = useState(parseInt(localStorage.getItem('dashboard-tab')) || 0);
    useEffect(() => {
        setUrl(`${process.env.REACT_APP_API}/store/${store}`);
    }, [store, setUrl]);

    const switchTab = (event, value) => {
        setOpenTab(value);
        localStorage.setItem('dashboard-tab', value);
    };

    const updateStore = (data, callback) => {
        fetch(`${process.env.REACT_APP_API}/store/${store}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
            .then((response) => refresh())
            .catch(console.error);
    };

    const updateTaggable = (tags) => {
        fetch(`${process.env.REACT_APP_API}/taggable/${store}`, {
            method: 'PUT',
            body: JSON.stringify({ taggable: { tags } })
        })
            .then((response) => refresh())
            .catch(console.error);
    };

    const concept = storeData.find((item) => item.id === store);

    // still loading
    if (!concept) return null;

    const presentations = storeData
        .filter((item) => item.behaviors && item.behaviors.presentation)
        .filter((item) => item.behaviors.presentation.concept === store);

    const openingtimes = concept.behaviors.openingtimes;

    const logo = concept.logo && storeData.find((item) => item.id === concept.logo);

    const photos = storeData.filter((item) => concept.photos.includes(item.id));

    return (
		<>
			<Tabs
			    value={openTab}
			    variant='fullWidth'
			    onChange={switchTab}
			>
			    <Tab label='Tietopankki' />
			    <Tab label='Aukioloajat' />
			    <Tab label='Liiketiedot' />
			    <Tab label='Perustiedot' />
			    <Tab label='Raportointi' />
			</Tabs>
			<Container maxWidth={false} style={{ paddingTop: 24, paddingBottom: 24 }}>
			    {openTab === 0 && (
			        <FileList owner={concept.mall} />
			    )}
			    {openTab === 1 && (
			        <Hours
			            store={concept}
			            defaultTimes={openingtimes}
			            onDefaultTimesChanged={refresh}
			        />
			    )}
			    {openTab === 2 && (
			        <StoreFront
			            store={concept}
			            logo={logo}
			            photos={photos}
			            updateStore={updateStore}
			            tags={concept.behaviors.taggable.tags}
			            updateTaggable={updateTaggable}
			        />
			    )}
			    {openTab === 3 && (
			        <StoreInfo
			            store={concept}
			            updateStore={updateStore}
			            presentations={presentations}
			        />
			    )}
			    {openTab === 4 && (
			        <StoreReports store={concept} />
			    )}
			</Container>
		</>
    );
};

Store.propTypes = {
    store: PropTypes.string.isRequired,
};

export default Store;
