import React, { useEffect, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RentDialog from './RentDialog';
import EditRentDialog from './EditRentDialog';
import LinkDialog from './LinkDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import { useFetch } from '../common/hooks';

const cformatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' });
const fformatter = new Intl.NumberFormat('fi-FI', { maximumFractionDigits: 1 });

const RentInfo = ({ store, rentdata, refreshParent, active, changeActivity }) => {
    const [{data, refresh}, setUrl] = useFetch(null);
    const contract = data ? data.contract : undefined;
    const [rentDialogOpen, setRentDialogOpen] = useState(false);
    const [editRentDialogOpen, setEditRentDialogOpen] = useState(false);
    const [linkDialogOpen, setLinkDialogOpen] = useState(false);
    const [selectedRentRow, setSelectedRentRow] = useState(false);
    useEffect(() => {
        setUrl(`${process.env.REACT_APP_API}/store/${store}/contract`);
    }, [store, setUrl, contract]);

    const removeRentRow = (store, row) => {
        fetch(`${process.env.REACT_APP_API}/rent/${store}/row/${row}`, { method: 'DELETE' })
            .then((response) => {
                showMessage('Vuokrarivi poistettu');
                refreshParent();
            })
            .catch(console.error);
    };

    const toggleActivity = (store) => {
        fetch(`${process.env.REACT_APP_API}/store/${store}/activity`, {
            method: 'PUT',
            body: JSON.stringify({
                active
            })
        })
            .then((response) => {
                showMessage(response.item.active ? 'Liike aktivoitu' : 'Liike passivoitu');
                changeActivity(response.item.active);
            })
            .catch(console.error);
    };

    const getDefaults = () => {
        let highest = '';
        let row = undefined;
        rentdata.forEach((rent) => {
            if (rent.enddate > highest) {
                highest = rent.enddate;
                row = rent;
            }
        });
        setSelectedRentRow(row);
    }

    const downloadAsCSV = () => {
        let BOM = '\ufeff'
        let table = document.getElementById('renttable');
        let header = Array.from(table.querySelectorAll('thead th'))
            .map((th) => th.textContent);

        let data = Array.from(table.querySelectorAll('tbody tr'))
            .filter((tr) => tr.childElementCount > 3)
            .map((tr) => Array.from(tr.querySelectorAll('td')).map((td) => td.textContent));

        let csv = BOM + [header, ...data].map((row) => row.join(';')).join('\n');

        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        downloadAnchorNode.setAttribute('download', 'vuokrat.csv');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    return (
        <Grid key='vuokratiedot' item sm={12}>
            <Toolbar disableGutters>
                <Typography variant='h5' style={{ flexGrow: 1 }}>
                    Vuokratiedot
	            </Typography>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        setRentDialogOpen(true);
                        getDefaults();
                    }}
                >
                    Lisää
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => { toggleActivity(store); }}
                    style={{ marginLeft: '1em' }}
                >
                    {active ? 'Passivoi liike' : 'Aktivoi liike'}
                </Button>
                <Button color='primary' variant='contained' onClick={() => downloadAsCSV()} style={{ margin: '0 1em' }}>
                    Lataa CSV
                </Button>
            </Toolbar>
            <Paper elevation={1}>
                <Table id='renttable'>
                    <colgroup>
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: '50px' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Alkamispäivä</TableCell>
                            <TableCell align='center'>Päättymispäivä</TableCell>
                            <TableCell align='center'>Vuokrakausi</TableCell>
                            <TableCell align='center'>Pinta-ala</TableCell>
                            <TableCell align='center'>Pääomavuokra</TableCell>
                            <TableCell align='center'>/m&sup2;</TableCell>
                            <TableCell align='center'>Ylläpitovuokra</TableCell>
                            <TableCell align='center'>/m&sup2;</TableCell>
                            <TableCell align='center'>Vuokra yhteensä</TableCell>
                            <TableCell align='center'>Lisätietoja</TableCell>
                            <TableCell ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rentdata.sort((a,b)=>moment(b.startdate)-moment(a.startdate)).map((rent) => {
                            return (
                                <TableRow key={rent.id} selected={moment().isBetween(rent.startdate, rent.enddate, undefined, '[]')}>
                                    <TableCell align='center'>{moment(rent.startdate).format('DD.MM.YYYY')}</TableCell>
                                    <TableCell align='center'>{rent.enddate
                                        ? moment(rent.enddate).format('DD.MM.YYYY')
                                        : '-'}
                                    </TableCell>
                                    <TableCell align='center'>{rent.contractenddate
                                        ? moment(rent.contractenddate).format('DD.MM.YYYY')
                                        : (rent.notice ? 'Irtisanomisaika ' + rent.notice + ' kk' : '-')}
                                    </TableCell>
                                    <TableCell align='center'>{fformatter.format(rent.surfacearea)} m&sup2;</TableCell>
                                    <TableCell align='center'>{cformatter.format(rent.netrent)}</TableCell>
                                    <TableCell align='center'>{cformatter.format(rent.netrent / rent.surfacearea)}/m&sup2;</TableCell>
                                    <TableCell align='center'>{cformatter.format(rent.servicecharges)}</TableCell>
                                    <TableCell align='center'>{cformatter.format(rent.servicecharges / rent.surfacearea)}/m&sup2;</TableCell>
                                    <TableCell align='center'>{cformatter.format(rent.netrent + rent.servicecharges)}</TableCell>
                                    <TableCell align='left'>{rent.info}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                        <Tooltip title='Muokkaa'>
                                            <IconButton aria-label='Muokkaa' size='small' style={{ marginRight: '1em' }} onClick={() => {
                                                setEditRentDialogOpen(true);
                                                setSelectedRentRow(rent);
                                            }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Poista'>
                                            <IconButton aria-label='Poista' size='small' onClick={() => {
                                                removeRentRow(store, rent.id)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
            <Typography key={contract} variant='h5' style={{ flexGrow: 1 }}>
                Linkki vuokrasopimukseen
                <Button
                    variant='contained'
                    color='primary'
                    style={{ margin: '0 1em' }}
                    onClick={() => setLinkDialogOpen(true)}
                >
                    Lisää
                </Button>
            </Typography>
            { contract ? <Link href={contract} target='_blank' rel='noopener'>{contract}</Link> : 'Ei linkitettyä vuokrasopimusta'}
            <RentDialog
                open={rentDialogOpen}
                rentdata={selectedRentRow}
                store={store}
                onSave={() => {
                    setRentDialogOpen(false);
                    refreshParent();
                }}
                onClose={() => setRentDialogOpen(false)}
            />
            <EditRentDialog
                open={editRentDialogOpen}
                row={selectedRentRow}
                store={store}
                onSave={() => {
                    setEditRentDialogOpen(false);
                    refreshParent();
                }}
                onClose={() => setEditRentDialogOpen(false)}
            />
            <LinkDialog
                open={linkDialogOpen}
                contract={contract}
                store={store}
                onSave={() => {
                    setLinkDialogOpen(false);
                    refresh();
                }}
                onClose={() => setLinkDialogOpen(false)}
            />
        </Grid>
    );
}
export default RentInfo;