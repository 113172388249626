import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { fetchWrap as fetch } from '../common/functions';
import { showMessage } from '../common';

const NewStoreDialog = ({ open, mall, onClose, onSave }) => {

    const [name, setName] = useState('');

    const createStore = () => {
        fetch(`${process.env.REACT_APP_API}/mall/${mall.id}/newstore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                store: {
                    name
                }
            })
        })
            .then((response) => {
                showMessage('Liike lisätty');
                onSave();
            })
            .catch(console.error);
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
				Uusi liike
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    type='text'
                    id='newStoreDialogName'
                    name='newStoreDialogName'
                    label='Nimi'
                    fullWidth
                    variant='filled'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' onClick={createStore}>
                    Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewStoreDialog;
