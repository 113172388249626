import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';

const FileListDialog = ({ open, owner, filelist, onClose, onSave }) => {
    const [title, setTitle] = useState('');
    useEffect(() => {
        if (open && filelist) {
            setTitle(filelist.title);
        }
    }, [open, filelist]);

    const createFileList = () => {
        fetch(`${process.env.REACT_APP_API}/resourceowner/${owner}/create`, {
            method: 'POST',
            body: JSON.stringify({
                resource: {
                    type: 'staticresourcelist',
                    title: title,
                }
            })
        })
            .then((response) => {
                showMessage('Liiteryhmä lisätty');
                onSave();
            })
            .catch((error) => {
                showMessage('Liiteryhmän lisäys epäonnistui', true);
                console.error(error);
            });
    };

    const updateFilelist = () => {
        fetch(`${process.env.REACT_APP_API}/staticresourcelist/${filelist.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                resource: {
                    type: 'staticresourcelist',
                    title: title
                }
            })
        })
            .then((response) => {
                showMessage('Liiteryhmä päivitetty');
                onSave();
            })
            .catch((error) => {
                showMessage('Liiteryhmän päivitys epäonnistui', true);
                console.error(error);
            });
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                {filelist ? 'Muokkaa liiteryhmää' : 'Uusi liiteryhmä'}
            </DialogTitle>
            <DialogContent>
                <TextField
                    type='text'
                    id='title'
                    name='title'
                    label='Nimi'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' onClick={filelist ? updateFilelist : createFileList}>
                    {filelist ? 'Tallenna' : 'Lisää'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FileListDialog;
