import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { userManager } from './store';

// Containers
import Callback from './callback';
import AdminPage from './containers/AdminPage';
import DashboardPage from './containers/DashboardPage';
import LoginPage from './containers/LoginPage';
import { DataBank } from '@project/components';

class App extends Component {

	state = {
		loading: true,
		items: [],
		user: null,
	}

	componentDidMount() {
		const options = {
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			}
		}

		fetch(`${process.env.REACT_APP_API}/acl/context`, options)
		.then((response) => {
			if (!response.ok) {
				if (response.status === 401) {
					this.setState({ loading: false });
				}
				throw new Error(response.status);
			}
			return response;
		})
		.then((response) => response.json())
		.then((response) => this.setState({
			loading: false,
			items: response.items,
			user: response.user,
		}))
		.catch((error) => this.setState({ loading: false }));
	}

	render() {
		const { loading, items, user } = this.state;

		if (loading !== false) return null;

		// showing AdminPage if user has context to mall
		// FIXME support for multiple malls
		const mall = items.find(item => item.type === 'mall');
		const stores = items.filter(item => item.type === 'storefront');

		return (
			<Switch>
				<Route path="/callback" component={Callback} />
				<Route path="/logout" component={() => {
					fetch(`${process.env.REACT_APP_API}/logout`, {
						method: 'POST',
						credentials: 'include',
					})
					.then((response) => {
						if (process.env.NODE_ENV === 'production') {
							userManager.signoutRedirect();
						} else {
							window.location.href = '/';
						}
					});
					return null;
				}} />

				{!user && <Route path="/" component={LoginPage} />}

				{user && mall && user.behaviors.privileged.contexts.find((context) => context.role.name === 'Vartija') && (
					<Route path="/admin" component={(props) =>
						<DataBank {...props} owner={mall.id} />
					}/>
				)}

				{user && mall &&
					<Route path="/admin" component={(props) =>
						<AdminPage {...props} user={user} mall={mall} />
					}/>
				}

				{user && stores.length > 0 &&
					<Route path="/admin" component={(props) =>
						<DashboardPage {...props} user={user}
							stores={stores.map(store => store.id)}
							contexts={[...new Set(stores.map(store => store.mall))]}
						/>
					}/>
				}

				{user && <Redirect from="/" to="/admin" />}
			</Switch>
		)
	}

}

export default App;
