import React, { useState } from 'react';
import { withTheme } from '@material-ui/core/styles';
import InputFiles from 'react-input-files';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmDialog, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import { useFetch } from '../common/hooks';
import { getOrderedResourceLists } from '../constants';
import FileListDialog from './FileListDialog';
import AddLinkDialog from './AddLinkDialog';
import ReadConfirmDialog from './ReadConfirmDialog';

const acceptedFiles = 'text/plain, image/*, .pdf, .docx';

const DataBank = ({ theme, owner }) => {
    const [{ data, refresh }] = useFetch(`${process.env.REACT_APP_API}/resourceowner/${owner}`);
    const items = data ? data.items : [];
    const resources = data ? data.resources : [];
    const resLists = items.filter((item) => resources.includes(item.id) && item.type === 'staticresourcelist');
    const orderedList = getOrderedResourceLists();
    const [fileListDialogOpen, setFileListDialogOpen] = useState(false);
    const [fileListDialogList, setFileListDialogList] = useState(null);
    const [deleteFileListDialogOpen, setDeleteFileListDialogOpen] = useState(false);
    const [deleteFileListDialogList, setDeleteFileListDialogList] = useState(null);
    const [deleteResourceDialogOpen, setDeleteResourceDialogOpen] = useState(false);
    const [deleteResourceDialogResouce, setDeleteResourceDialogResource] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);
    const [addLinkDialogOpen, setAddLinkDialogOpen] = useState(false);
    const [addLinkDialogList, setAddLinkDialogList] = useState(null);
    const [readConfirmDialogOpen, setReadConfirmDialogOpen] = useState(false);
    const [readConfirmDialogConfirmable, setReadConfirmDialogConfirmable] = useState(null);

    const uploadFile = (list) => (files) => {
        const promises = [];

        Array.from(files).forEach((file) => {
            const formData = new FormData();
            const fileName = file.name.split(' ').join('_');
            formData.append('file', file, fileName);
            formData.append('name', fileName);

            promises.push(
                fetch(`${process.env.REACT_APP_API}/filestore/upload`, {
                    method: 'POST',
                    body: formData
                })
                    .then((response) => response.item)
                    .then((file) => {
                        if (!file) return;
                        fetch(`${process.env.REACT_APP_API}/staticresourcelist/${list}/resource/${file.id}`, { method: 'POST' })
                            .catch(console.error);
                    })
                    .catch(console.error)
            );
        });

        Promise.all(promises).then((data) => {
            showMessage('Tiedostot lisätty');
            refresh();
        });
    };

    const openResource = (resource) => () => {
        switch (resource.type) {
            case 'file':
                window.open(`${process.env.REACT_APP_API}/filestore/download/inline/${resource.id}/${resource.name}`, resource.id);
                break;
            case 'externallink':
                window.open(resource.url, resource.id);
                break;
            default:
                break;
        }
    };

    const deleteResource = (list, resource) => {
        fetch(`${process.env.REACT_APP_API}/staticresourcelist/${list}/resource/${resource.id}`, { method: 'DELETE' })
            .then((response) => {
                showMessage('Tiedosto poistettu');
                refresh();
            })
            .catch((error) => {
                showMessage('Tiedoston poistaminen epäonnistui', true);
                console.error(error);
            });
    };

    const deleteList = (list) => {
        fetch(`${process.env.REACT_APP_API}/resourceowner/${owner}/resource/${list}`, { method: 'DELETE' })
            .then((response) => {
                showMessage('Liiteryhmä poistettu');
                refresh();
            })
            .catch((error) => {
                showMessage('Liiteryhmän poistaminen epäonnistui', true);
                console.error(error);
            });
    };

    const compare = (a, b) => {
        const aName = a.name ? a.name : a.title;
        const bName = b.name ? b.name : b.title;

        return aName.localeCompare(bName);
    };

    const handleFind = (title) => {
        let result;
        const idx = resLists.findIndex((resourcelist) => resourcelist.title === title);
        if (idx > -1) {
            result = resLists[idx];
            resLists.splice(idx, 1);
        } else {
            result = '';
        }
        return result;
    };

    const getGridElement = (resourcelist) => {
        if (resourcelist) {
            return (
                <Grid key={resourcelist.id} container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Toolbar disableGutters>
                            <Typography variant='h6'
                                onClick={() => {
                                    setFileListDialogOpen(true);
                                    setFileListDialogList(resourcelist);
                                }}
                            >
                                {resourcelist.title || <small>&lt;nimetön&gt;</small>}
                            </Typography>
                        </Toolbar>
                        <Paper style={{ minHeight: '200px', maxHeight: 'calc(100vh - 190px)', overflow: 'auto' }}>
                            <List>
                                {items.filter((item) => resourcelist.behaviors.resourceowner.resources.includes(item.id))
                                    .sort((a, b) => compare(a, b))
                                    .map((resource) => (
                                        <ListItem
                                            key={resource.id}
                                            button
                                            selected={selectedResource === resource}
                                            onClick={() => setSelectedResource(resource)}
                                        >
                                            <ListItemText disableTypography>
                                                <Typography variant='body2'>
                                                    {resource.type === 'file' && resource.name}
                                                    {resource.type === 'externallink' && resource.title}
                                                </Typography>
                                            </ListItemText>
                                            {selectedResource === resource &&
                                                <ListItemSecondaryAction>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={openResource(resource)}
                                                    >
                                                        Avaa
                                                    </Button>
                                                    <IconButton aria-label='Poista' onClick={() => {
                                                        setDeleteResourceDialogOpen(true);
                                                        setDeleteResourceDialogResource({ resourcelist, resource });
                                                    }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            }
                                        </ListItem>
                                    ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <InputFiles
                            accept={acceptedFiles}
                            multiple={true}
                            onChange={uploadFile(resourcelist.id, refresh)}
                            style={{ width: '100%' }}
                        >
                            <Button fullWidth variant='contained' color='primary'>Lisää tiedosto</Button>
                        </InputFiles>

                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                setAddLinkDialogOpen(true);
                                setAddLinkDialogList(resourcelist.id);
                            }}
                            style={{ marginTop: '1em' }}
                        >
                            Lisää linkki
                        </Button>

                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                setReadConfirmDialogConfirmable(resourcelist.id);
                                setReadConfirmDialogOpen(true);
                            }}
                            style={{ marginTop: '1em' }}
                        >
                            Tarkastele kuittauksia
                        </Button>


                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                setDeleteFileListDialogOpen(true);
                                setDeleteFileListDialogList(resourcelist);
                            }}
                            style={{ marginTop: '1em', backgroundColor: theme.palette.error.main }}
                        >
                            Poista liiteryhmä
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <></>
            );
        }

    };

    return (
        <Container maxWidth={false}>
            <Toolbar disableGutters>
                <Typography variant='h5'>
                    Tietopankki
                </Typography>
            </Toolbar>
            <Toolbar disableGutters>
                <Button variant='contained' color='primary' onClick={() => {
                    setFileListDialogList(null);
                    setFileListDialogOpen(true);
                }}>
                    Uusi liiteryhmä
                </Button>
            </Toolbar>

            {orderedList.map((item) => getGridElement(handleFind(item)))}
            {resLists.length > 0 && resLists.map((resourcelist) => getGridElement(resourcelist))}

            <FileListDialog
                open={fileListDialogOpen}
                filelist={fileListDialogList}
                owner={owner}
                onClose={() => setFileListDialogOpen(false)}
                onSave={() => {
                    setFileListDialogOpen(false);
                    refresh();
                }}
            />
            <AddLinkDialog
                open={addLinkDialogOpen}
                resourcelist={addLinkDialogList}
                onClose={() => setAddLinkDialogOpen(false)}
                onSave={() => {
                    setAddLinkDialogOpen(false);
                    refresh();
                }}
            />
            <ReadConfirmDialog
                owner={owner}
                open={readConfirmDialogOpen}
                confirmable={readConfirmDialogConfirmable}
                onClose={() => setReadConfirmDialogOpen(false)}
            />
            <ConfirmDialog
                open={deleteResourceDialogOpen}
                title='Poista tiedosto'
                text='Haluatko varmasti poistaa tiedoston?'
                confirmText='Poista'
                onClose={() => setDeleteResourceDialogOpen(false)}
                onConfirm={() => {
                    setDeleteResourceDialogOpen(false);
                    deleteResource(deleteResourceDialogResouce.resourcelist.id, deleteResourceDialogResouce.resource);
                }}
            />
            <ConfirmDialog
                open={deleteFileListDialogOpen}
                title='Poista liiteryhmä'
                text='Haluatko varmasti poistaa liiteryhmän?'
                confirmText='Poista'
                onClose={() => setDeleteFileListDialogOpen(false)}
                onConfirm={() => {
                    setDeleteFileListDialogOpen(false);
                    deleteList(deleteFileListDialogList.id);
                }}
            />
        </Container>
    );
};

export default withTheme(DataBank);
