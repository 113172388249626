import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Provider } from 'react-redux';
import { I18nProvider } from '@lingui/react';
import { OidcProvider } from 'redux-oidc';

import { Notifier, IEnotifier } from '@project/components';
import App from './App';
import theme from './theme';
import store, { userManager } from './store';

ReactDOM.render(
	<BrowserRouter>
		<I18nProvider>
			<Provider store={store}>
				<OidcProvider store={store} userManager={userManager}>
					<ThemeProvider theme={theme}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<CssBaseline />
							<App />
							<Notifier />
							<IEnotifier />
						</MuiPickersUtilsProvider>
					</ThemeProvider>
				</OidcProvider>
			</Provider>
		</I18nProvider>
	</BrowserRouter>
	, document.getElementById('root'));
