import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetch } from '../common/hooks';
import moment from 'moment';

const ReadConfirmDialog = ({ open, owner, confirmable, onClose }) => {
    const [{ data: confirmedData, loading: confirmedLoading }, setConfirmedUrl] = useFetch(null);
    const confirmed = confirmedData ? confirmedData.confirmed : [];
    const [{ data: storedata, loading: storeLoading }, setStoreUrl] = useFetch(null);
    const stores = storedata ? storedata.results.sort((a,b) => a.label > b.label) : [];
    const [{ data: userData, loading: userLoading }, setUserUrl] = useFetch(null);
    const users = userData ? userData.items : [];
    const roles = userData ? userData.roles : [];
    const loading = confirmedLoading || storeLoading || userLoading;
    useEffect(() => {
        if (open && confirmable && owner) {
            setConfirmedUrl(`${process.env.REACT_APP_API}/readconfirmable/${confirmable}`);
            setStoreUrl(`${process.env.REACT_APP_API}/mall/${owner}/search?term=`);
            setUserUrl(`${process.env.REACT_APP_API}/users/list`);
        }
    }, [open, confirmable, owner, setConfirmedUrl, setStoreUrl, setUserUrl]);
    return (
        <Dialog open={open} maxWidth={false}>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Henkilö</TableCell>
                            <TableCell>Kellonaika</TableCell>
                            <TableCell>Oikeudet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && confirmed.map((confirmation) => {
                            const user = users.find((user) => user.id === confirmation.privileged);
                            const contexts = user && user.behaviors.privileged.contexts.map((ctx) => {
                                const store = stores.find((store) => store.value === ctx.context);
                                const role = roles.find((role) => role.id === ctx.role);
                                return {
                                    store: store && store.label,
                                    role: role && role.name,
                                };
                            });

                            return (
                                <TableRow key={confirmation.privileged}>
                                    <TableCell><CheckIcon /></TableCell>
                                    <TableCell>{(user && user.name) || <CircularProgress/>}</TableCell>
                                    <TableCell>{moment(confirmation.timestamp).format('LLL')}</TableCell>
                                    <TableCell>
                                        {(contexts && contexts.map(({ store, role }) =>
                                            <Typography inline>{role} {store && `/ ${store}`}</Typography>))
											|| <CircularProgress/>
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {loading && (
                    <Grid container direction='column' alignItems='center'>
                        <Grid item style={{ padding: '2em' }}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
					Sulje
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReadConfirmDialog;
