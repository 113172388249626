import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetch } from '../common/hooks';

import NewUserDialog from './NewUserDialog';
import SelectUserDialog from './SelectUserDialog';
import NewStoreDialog from './NewStoreDialog';
import UserTable from './UserTable';

const AccessManagement = ({ mall }) => {
    const [{ data: storeData, refresh: refreshStores }] = useFetch(`${process.env.REACT_APP_API}/mall/${mall.id}/search?term=`);
    const stores = storeData ? storeData.results.sort((a, b) => a.label > b.label ? 1 : -1) : [];
    const [{ data: userData, refresh: refreshUsers }] = useFetch(`${process.env.REACT_APP_API}/users/list`);
    const users = userData ? userData.items : [];
    const roles = userData ? userData.roles : [];
    const [selectedContext, setSelectedContext] = useState(null);
    const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
    const [selectUserDialog, setSelectUserDialog] = useState(false);
    const [selectUserDialogFilter, setSelectUserDialogFilter] = useState('');
    const [newStoreDialogOpen, setNewStoreDialogOpen] = useState(false);

    const disabledRoles = roles.filter((role) => role.privileges.includes('grant.master')).map((role) => role.id);
    const mallcontext = { value: mall.id, label: mall.name };

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Toolbar disableGutters={true}>
                        <Typography variant='h3' style={{ flexGrow: 1, paddingBottom: '0.25em' }}>
                            {mallcontext.label}
                        </Typography>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ marginRight: '1em' }}
                            onClick={() => setNewStoreDialogOpen(true)}
                        >
							Luo uusi liike
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                setSelectedContext(mallcontext.value);
                                setSelectUserDialog(true);
                            }}
                        >
							Lisää käyttäjä
                        </Button>
                    </Toolbar>
                    {users.length !== 0 && (
                        <UserTable
                            context={mallcontext.value}
                            users={users.filter((user) => user.behaviors.privileged.contexts.some((c) => c.context === mallcontext.value))}
                            roles={roles}
                            disabledRoles={disabledRoles}
                            onChange={refreshUsers}
                        />
                    )}
                    {users.length === 0 && (
                        <Grid container direction='column' alignItems='center'>
                            <Grid item style={{ padding: '1em' }}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h3'>
						Liikkeet
                    </Typography>
                </Grid>

                {stores.map((context) => (
                    <Grid item xs={12} key={context.value}>
                        <Toolbar disableGutters={true}>
                            <Typography variant='h5' style={{ flexGrow: 1 }}>
                                {context.label}
                            </Typography>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    setSelectedContext(context.value);
                                    setSelectUserDialog(true);
                                }}
                            >
								Lisää käyttäjä
                            </Button>
                        </Toolbar>

                        {users.length !== 0 && (
                            <UserTable
                                context={context.value}
                                users={users.filter((user) => user.behaviors.privileged.contexts.some((c) => c.context === context.value))}
                                roles={roles}
                                disabledRoles={disabledRoles}
                                onChange={refreshUsers}
                            />
                        )}
                        {users.length === 0 && <Grid container direction='column' alignItems='center'>
                            <Grid item style={{ padding: '1em' }}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>}
                    </Grid>
                ))}
            </Grid>
            {stores.length === 0 && (
                <Grid container direction='column' alignItems='center'>
                    <Grid item>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            )}
            <SelectUserDialog
                open={selectUserDialog}
                users={users}
                context={selectedContext}
                filter={selectUserDialogFilter}
                onFilterChange={(event) => setSelectUserDialogFilter(event.target.value)}
                onAddNew={() => {
                    setSelectUserDialog(false);
                    setNewUserDialogOpen(true);
                }}
                onClose={() => {
                    refreshUsers();
                    setSelectUserDialog(false);
                }}
            />
            <NewUserDialog
                open={newUserDialogOpen}
                context={selectedContext}
                prefilledName={selectUserDialogFilter}
                onClose={() => {
                    setSelectedContext(null);
                    setNewUserDialogOpen(false);
                }}
                onSave={() => {
                    setNewUserDialogOpen(false);
                    refreshUsers();
                }}
            />
            <NewStoreDialog
                open={newStoreDialogOpen}
                mall={mall}
                context={selectedContext}
                onClose={() => setNewStoreDialogOpen(false)}
                onSave={() => {
                    setNewStoreDialogOpen(false);
                    refreshStores();
                }}
            />
        </Container>
    );
};

export default AccessManagement;
