import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { fetchWrap as fetch } from '../common/functions';
import { ConfirmDialog, showMessage } from '../common';
import EditUserDialog from './EditUserDialog';

const ExternalStatusIcon = ({ status }) => {
    if (status === 'synced') {
        return (
            <Tooltip title='Käyttäjä aktiivinen'>
                <CheckIcon />
            </Tooltip>
        );
    }
    if (status === 'failed') {
        return (
            <Tooltip title='Virhe aktivoinnissa'>
                <ErrorIcon color='error' />
            </Tooltip>
        );
    }
    if (status === null) {
        return (
            <Tooltip title='Odottaa aktivointia'>
                <AutorenewIcon />
            </Tooltip>
        );
    }
    return (
        <Tooltip title='Tuntematon tila'>
            <ErrorOutlineIcon />
        </Tooltip>
    );
};

function formatPhoneNumber(phone) {
	if (phone == null || phone === '' || !phone.startsWith('+358')) {
		return phone;
	}
	let p = phone.replace(/\s/g, '');
	return p.slice(0, 4) + ' ' + p.slice(4, 6) + ' ' + p.slice(6, 10) + ' ' + p.slice(10);
}

const UserTable = ({ context, users, roles, disabledRoles, onChange }) => {

    const [selectedUser, setSelectedUser] = useState(null);
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

    const grantRole = (context, user, role) => {
        fetch(`${process.env.REACT_APP_API}/acl/role/grant/${role}/${user}/${context}`, { method: 'POST' })
            .then((response) => onChange())
            .catch(console.error);
    };

    const revokeRole = (context, user) => {
        fetch(`${process.env.REACT_APP_API}/acl/role/revoke/${user}/${context}`, { method: 'POST' })
            .then((response) => onChange())
            .catch(console.error);
    };

    const onRoleChange = (context, user) => (event) => {
        const role = event.target.value;
        if (role) {
            grantRole(context, user, role);
        } else {
            revokeRole(context, user);
        }
    };

    const removeContext = (context, user) => {
        fetch(`${process.env.REACT_APP_API}/acl/context/${user}/${context}`, { method: 'DELETE' })
            .then((response) => {
                showMessage('Käyttäjä poistettu');
                onChange();
            })
            .catch(console.error);
    };

    const roleForUser = (user) => {
        return user.behaviors.privileged.contexts.find((c) => c.context === context).role || '';
    };

    return (
		<>
			<Paper elevation={1}>
			    <Table>
			        <colgroup>
			            <col style={{ width: '30px' }} />
			            <col style={{ width: 'auto' }} />
			            <col style={{ width: 'auto' }} />
			            <col style={{ width: '170px' }} />
			            <col style={{ width: '300px' }} />
			            <col style={{ width: '50px' }} />
			        </colgroup>
			        <TableHead>
			            <TableRow>
			                <TableCell padding='none'></TableCell>
			                <TableCell>Nimi</TableCell>
			                <TableCell>Sähköposti</TableCell>
			                <TableCell>Puhelin</TableCell>
			                <TableCell>Rooli</TableCell>
			                <TableCell></TableCell>
			            </TableRow>
			        </TableHead>
			        <TableBody>
			            {users.map((user) => (
			                <TableRow key={user.id}>
			                    <TableCell padding='none' align='right'>
			                        <ExternalStatusIcon status={user.behaviors.privileged.external_status} />
			                    </TableCell>
			                    <TableCell>{user.name}</TableCell>
			                    <TableCell>{user.email}</TableCell>
			                    <TableCell>{formatPhoneNumber(user.phone)}</TableCell>
			                    <TableCell align='right' padding='checkbox'>
			                        <TextField
			                            select
			                            fullWidth
			                            value={roleForUser(user)}
			                            onChange={onRoleChange(context, user.id)}
			                            disabled={disabledRoles.includes(roleForUser(user))}
			                            SelectProps={{ native: true }}
			                        >
			                            <option></option>
			                            {roles.map((role) => (
			                                <option key={role.id} value={role.id} disabled={disabledRoles.includes(role.id)}>
			                                    {role.name}
			                                </option>
			                            ))}
			                        </TextField>
			                    </TableCell>
			                    <TableCell style={{ whiteSpace: 'nowrap' }}>
			                        <Tooltip title='Muokkaa'>
			                            <IconButton aria-label='Muokkaa' size='small' style={{ marginRight: '1em' }} onClick={() => {
			                                setEditUserDialogOpen(true);
			                                setSelectedUser(user);
			                            }}>
			                                <EditIcon />
			                            </IconButton>
			                        </Tooltip>
			                        <Tooltip title='Poista'>
			                            <IconButton aria-label='Poista' size='small' onClick={() => {
			                                setDeleteUserDialogOpen(true);
			                                setSelectedUser(user);
			                            }}>
			                                <DeleteIcon />
			                            </IconButton>
			                        </Tooltip>
			                    </TableCell>
			                </TableRow>
			            ))}
			        </TableBody>
			    </Table>
			</Paper>
			<EditUserDialog
			    open={editUserDialogOpen}
			    user={selectedUser}
			    onClose={() => setEditUserDialogOpen(false)}
			    onSave={() => {
			        setEditUserDialogOpen(false);
			        onChange();
			    }}
			/>
			<ConfirmDialog
			    open={deleteUserDialogOpen}
			    title='Poista käyttäjä'
			    text={`Haluatko varmasti poistaa käyttäjän ${selectedUser ? selectedUser.name : ''}?`}
			    confirmText='Poista'
			    onClose={() => setDeleteUserDialogOpen(false)}
			    onConfirm={() => {
			        setDeleteUserDialogOpen(false);
			        removeContext(context, selectedUser.id);
			    }}
			/>
		</>
    );
};

export default UserTable;
