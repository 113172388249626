import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { withTheme } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/fi';
import { showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch } from '../common/functions';

import RangeSelector from './RangeSelector';
import OverrideDaySelector from './OverrideDaySelector';

const momentEn = moment();
momentEn.locale('en');

const weekdaysEn = momentEn.localeData().weekdaysShort().map((weekday) => weekday.toLowerCase());

const Hours = ({ store, defaultTimes, theme, onDefaultTimesChanged }) => {
    const [{ data, refresh }] = useFetch(`${process.env.REACT_APP_API}/openingtimes/${store.id}/irregulars`);
    const openingtimes = data ? data.openingtimes : [];
    const unconfirmed = data ? data.unconfirmed : [];
    const [dayPickerOpen, setDayPickerOpen] = useState(false);

    const changeDefault = (day) => (value) => {
        fetch(`${process.env.REACT_APP_API}/openingtimes/${store.id}/defaults`, {
            method: 'PUT',
            body: JSON.stringify({ openingtimes: { [day]: value ? [value] : [] } })
        })
            .then((response) => onDefaultTimesChanged(response.openingtimes))
            .catch((error) => {
                showMessage('Ajan päivittäminen epäonnistui', true);
                console.error(error);
            });
    };

    const createIrregular = (day, name, callback) => (range) => {
        let ranges = [];
        if (range) {
            ranges.push(range);
        }

        fetch(`${process.env.REACT_APP_API}/openingtimes/${store.id}/irregular/${day}`, {
            method: 'POST',
            body: JSON.stringify({ ranges, name })
        })
            .then((response) => callback())
            .catch((error) => {
                showMessage('Ajan lisääminen epäonnistui', true);
                console.error(error);
            });
    };

    const updateIrregular = (day, callback) => (range) => {
        let ranges = [];
        if (range) {
            ranges.push(range);
        }

        fetch(`${process.env.REACT_APP_API}/openingtimes/${store.id}/irregular/${day}`, {
            method: 'PUT',
            body: JSON.stringify({ ranges })
        })
            .then((response) => callback())
            .catch(console.error);
    };

    const removeIrregular = (day, callback) => () => {
        fetch(`${process.env.REACT_APP_API}/openingtimes/${store.id}/irregular/${day}`, {
            method: 'DELETE'
        })
            .then((response) => callback())
            .catch(console.error);
    };

    const defaultForDay = (day) => {
        const date = new Date(day);
        return defaultTimes[weekdaysEn[date.getUTCDay()]][0];
    };

    const handleDayPicker = (callback) => (day, name) => {
        createIrregular(day, name, callback)(defaultForDay(day));
        setDayPickerOpen(false);
    };

    // passing true to weekdays() sorts by locale, otherwise sunday first
    // use sunday first order to make map of localised name to english short name
    // then we can loop locale ordered list of weekdays
    const weekdays = moment.weekdays();
    const weekdayMap = weekdaysEn.reduce((o, weekday) => Object.assign(o, { [weekdays.shift()]: weekday }), {});

    let overrides = {};
    openingtimes.forEach((openingtime) => overrides[openingtime.day] = openingtime);
    unconfirmed.forEach((openingtime) => overrides[openingtime.day] = openingtime);

    let ordered = [];
    Object.keys(overrides).sort().forEach((key) => ordered.push(overrides[key]));

    let disabledDays = openingtimes.map((openingtime) => new Date(openingtime.day));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={5}>
                <Toolbar disableGutters>
                    <Typography variant='h5'>
						Normaalit aukioloajat
                    </Typography>
                </Toolbar>
                <Paper elevation={1}>
                    <Table padding='none'>
                        <TableHead>
                            <TableRow>
                                <TableCell padding='default'>Päivämäärä</TableCell>
                                <TableCell align='center'>Mistä</TableCell>
                                <TableCell align='center'>Mihin</TableCell>
                                <TableCell align='center'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {moment.weekdays(true).map((weekday) => (
                                <TableRow key={weekday}>
                                    <TableCell style={{ paddingLeft: '1em', textTransform: 'capitalize' }}>
                                        <Typography variant='body2'>{weekday}</Typography>
                                    </TableCell>
                                    <TableCell colSpan={3}>
                                        <RangeSelector theme={theme} range={defaultTimes[weekdayMap[weekday]][0]} onChange={changeDefault(weekdayMap[weekday])}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={7}>
                <Toolbar disableGutters>
                    <Typography variant='h5' style={{ flexGrow: 1 }}>
						Poikkeusajat
                    </Typography>
                    <Button variant='contained' color='primary' onClick={() => setDayPickerOpen(true)}>
						Lisää poikkeuspäivä
                    </Button>
                </Toolbar>
                <OverrideDaySelector
                    theme={theme}
                    onClose={() => setDayPickerOpen(false)}
                    open={dayPickerOpen}
                    disabledDays={disabledDays}
                    onClick={handleDayPicker(refresh)}
                />
                <Paper elevation={1}>
                    <Table padding='none'>
                        <TableHead>
                            <TableRow>
                                <TableCell padding='default'>Päivämäärä</TableCell>
                                <TableCell align='center'>Mistä</TableCell>
                                <TableCell align='center'>Mihin</TableCell>
                                <TableCell align='center'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ordered.map((override) => (
                                <TableRow key={override.day}>
                                    <TableCell padding='default'>
                                        {override.name && <Typography variant='subtitle1'>{override.name}</Typography>}
                                        <Typography variant={override.name ? 'caption' : 'body2'}>{(new Date(override.day)).toLocaleDateString('fi-FI')}</Typography>
                                    </TableCell>
                                    <TableCell align='center' colSpan={2}>
                                        {override.ranges
                                            ? <RangeSelector theme={theme} range={override.ranges[0]} onChange={updateIrregular(override.day, refresh)} />
                                            : <Button variant='contained' color='primary' onClick={() => createIrregular(override.day, override.name, refresh)(defaultForDay(override.day))}>Lisää poikkeusaika</Button>
                                        }
                                    </TableCell>
                                    <TableCell padding='checkbox'>
                                        {override.ranges &&
											<Tooltip title='Poista'>
											    <IconButton onClick={removeIrregular(override.day, refresh)}>
											        <DeleteIcon style={theme.deleteIcon}/>
											    </IconButton>
											</Tooltip>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default withTheme(Hours);
