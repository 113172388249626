import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import { useFetch } from '../common/hooks';

import RentInfo from './RentInfo';
import ProfitInfo from './ProfitInfo';

const Rent = ({ store, active, changeActivity }) => {
	const [{ data, refresh }, setUrl] = useFetch(null);
	const rentdata = data ? data.items : [];
    const [openTab, setOpenTab] = useState(parseInt(localStorage.getItem('rent-dashboard-tab')) || 0);
    useEffect(() => {
		setUrl(`${process.env.REACT_APP_API}/rent/${store}`);
    }, [store, setUrl]);
    const switchTab = (event, value) => {
		setOpenTab(value);
        localStorage.setItem('rent-dashboard-tab', value);
	};

    // still loading
    if (!data) return null;

    return (
		<>
			<Tabs
			    value={openTab}
			    variant='fullWidth'
			    onChange={switchTab}
			>
			    <Tab label='Vuokraustiedot' />
			    <Tab label='Tulostiedot' />
			</Tabs>
			<Container maxWidth={false} style={{ paddingTop: 24, paddingBottom: 24 }}>
			    {openTab === 0 && (
					<RentInfo
						rentdata={rentdata}
						store={store}
						active={active}
						changeActivity={changeActivity}
						refreshParent={refresh}
					/>
			    )}
			    {openTab === 1 && (
			        <ProfitInfo
						rentdata={rentdata}
						store={store}
						refresh={refresh}
					/>
			    )}
			</Container>
		</>
    );
};

Rent.propTypes = {
    store: PropTypes.string.isRequired,
};

export default Rent;