import React from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';

const TimeSelector = ({ timestamp, onChange }) => {
    const changeWrapper = (what) => (event) => {
        let orig = timestamp.split('.');
        let temp = [...orig];
        let value = event.target.value.padStart(2, '0');
        switch (what) {
            case 'hour':
                temp[0] = value;
                break;
            case 'minute':
                temp[1] = value;
                break;
            default:
                throw new Error(`Unknown selector [${what}], accepted values: hour, minute`);
        }
        onChange(temp.join('.'));
    };

    return (
		<>
			<NativeSelect
			    value={parseInt(timestamp.split('.')[0])}
			    onChange={changeWrapper('hour')}
			    style={{ width: 45 }}
			>
			    {Array.from(Array(25).keys()).map((option) => (
			        <option key={option} value={option}>
			            {option}
			        </option>
			    ))}
			</NativeSelect>
			<span>.</span>
			<NativeSelect
			    value={timestamp.split('.')[1]}
			    onChange={changeWrapper('minute')}
			    style={{ width: 45 }}
			>
			    {['00','15','30','45'].map((option) => (
			        <option key={option} value={option}>
			            {option}
			        </option>
			    ))}
			</NativeSelect>
		</>
    );
};

export default TimeSelector;
