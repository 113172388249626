import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetch } from '../common/hooks';

import StoreMonthReport from '../reporting/StoreMonthReport';
import ReportDialog from './ReportDialog';
import { months } from '../constants';
import moment from 'moment';

const StoreReports = ({ store }) => {
    const [{ data, refresh }] = useFetch(`${process.env.REACT_APP_API}/store/${store.id}/sales`);
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const items = data ? data.items : [];
    const [firstElement, setFirstElement] = useState(moment().subtract(1, 'M'));
        const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [reportDialogReport, setReportDialogReport] = useState(null);

    const downloadAsCSV = () => {
        let BOM = '\ufeff'
        let table = document.getElementById('reportingtable');
        let header = Array.from(table.querySelectorAll('thead th'))
            .map((th) => th.textContent);

        let data = Array.from(table.querySelectorAll('tbody tr'))
            .filter((tr) => tr.childElementCount > 3)
            .map((tr) => Array.from(tr.querySelectorAll('td')).map((td) => td.textContent));
        let csv = BOM + [header, ...data].map((row) => row.join(';')).join('\n');

        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        downloadAnchorNode.setAttribute('download', 'raportit.csv');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    const prevMonth = () => {
        setFirstElement(moment(firstElement.subtract(1, 'M')));
    };

    const nextMonth = () => {
            setFirstElement(moment(firstElement.add(1, 'M')));
        };

    if (!data) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Toolbar disableGutters>
                    <Typography variant='h5' style={{ flexGrow: 1 }}>
                        Raportointi
                    </Typography>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={moment(firstElement).format('YYYY-MM') === moment().subtract(1, 'M').format('YYYY-MM')}
                        onClick={() => nextMonth()}
                        style={{ margin: '0 1em' }}
                    >
                        Seuraava kuukausi
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={items.length === 0 || moment(firstElement).subtract(11, 'M').format('YYYY-MM') <= moment(items[items.length - 1].date).format('YYYY-MM')}
                        onClick={() => prevMonth()}
                        style={{ margin: '0 1em' }}
                    >
                        Edellinen kuukausi
                    </Button>
                    <Button color='primary' variant='contained' onClick={() => downloadAsCSV()} style={{ marginLeft: '1em' }}>
                        Lataa CSV
                    </Button>
                </Toolbar>
                <Paper elevation={1}>
                    <Table id='reportingtable'>
                        <colgroup>
                            <col style={{ width: 'auto' }} />
                            <col style={{ width: '70px' }} />
                            <col style={{ width: '70px' }} />
                            <col style={{ width: '120px' }} />
                            <col style={{ width: '70px' }} />
                            <col style={{ width: '120px' }} />
                            <col style={{ width: '70px' }} />
                            <col style={{ width: '40px' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>Kuukausi</TableCell>
                                <TableCell padding='none' align='right'>Kuitit</TableCell>
                                <TableCell padding='none' align='center'>Δ</TableCell>
                                <TableCell padding='none' align='right'>Myynti</TableCell>
                                <TableCell padding='none' align='center'>Δ</TableCell>
                                <TableCell padding='none' align='right'>Vuoden alusta</TableCell>
                                <TableCell padding='none' align='center'>Δ</TableCell>
                                <TableCell padding='none'></TableCell>
                                <TableCell padding='none'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from(Array(12)).map((_, index) => {
                                const month = moment(firstElement).add(5, 'd').subtract(index, 'M');
                                const monthstr = month.format('YYYY-MM');
                                const report = items.find((report) => report.date === monthstr + '-01');
                                return (
                                    <TableRow key={monthstr}>
                                        <TableCell>{months[month.month()]} {month.year()}</TableCell>
                                        <StoreMonthReport store={store.id} month={monthstr} timestamp={timestamp} />
                                        <TableCell>
                                            {report && (
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => {
                                                        setReportDialogOpen(true);
                                                        setReportDialogReport(report);
                                                    }}
                                                >
                                                    Muokkaa
                                                </Button>
                                            )}
                                            {items && !report && (
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => {
                                                        setReportDialogOpen(true);
                                                        setReportDialogReport({ date: monthstr + '-01' });
                                                    }}
                                                >
                                                    Lisää
                                                </Button>
                                            )}
                                            {!report && !items && <CircularProgress />}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
                <ReportDialog
                    open={reportDialogOpen}
                    report={reportDialogReport}
                    store={store}
                    onClose={() => setReportDialogOpen(false)}
                    onSave={() => {
                        setReportDialogOpen(false);
                        setTimestamp(new Date().getTime());
                        refresh();
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default StoreReports;
