import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

const serviceLabels = {
    www: 'WWW sivu',
    appstore: 'App Store',
    playstore: 'Google Play',
};

const ServiceLink = ({ links, name, label, onChange, ...attributes }) => {
    return (
        <TextField
            name={name}
            label={label}
            onChange={(event) => onChange(name, event.target.value)}
            fullWidth
            margin='normal'
            InputLabelProps={{
                shrink: true,
                style: {
                    textTransform: 'capitalize'
                }
            }}
            {...attributes}
        />
    );
};

class Links extends Component {

	state = {
	    links: this.props.someLinks,
	}

	updateLink = (service, uri) => {
	    const { links } = this.state;
	    const object = links.filter((link) => link.service === service);
	    // embrace the shallowness!
	    // update first with same service name if found or add as new
	    if (object.length > 0) {
	        if (uri.length === 0) {
	            links.splice(links.indexOf(object[0]), 1);
	        } else {
	            object[0].uri = uri;
	        }
	    } else if (uri.length > 0) {
	        links.push({ service, uri });
	    }
	    this.setState({ links });
	    this.props.updateLinks(links);
	}

	linkForService(service) {
	    const found = this.state.links.filter((link) => link.service === service);
	    return found.length > 0 ? found[0].uri : '';
	}

	render() {
	    let uriByService = this.state.links
	        .reduce((acc, item) => acc = { ...acc, [item.service]: item.uri }, {});

	    return ['www', 'facebook', 'instagram', 'tikTok', 'x', 'foodora', 'wolt', 'appstore', 'playstore'].map((link, index) => (
	        <ServiceLink
	            key={index}
	            onChange={this.updateLink}
	            value={uriByService[link] || ''}
	            name={link}
	            label={serviceLabels[link] || link}
	        />
	    ));
	}
};

export default Links;
