import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';

export default function LinkDialog({ open, store, contract, onSave, onClose }) {
    const [ctr, setCtr] = useState(null);

    useEffect(() => {
        if (contract) {
            if (open) {
                setCtr(contract)
            }
        }
    }, [open, contract]);

    const addContract = () => {
        fetch(`${process.env.REACT_APP_API}/store/${store}/contract`, {
            method: 'POST',
            body: JSON.stringify({
                ctr,
            })
        })
            .then((response) => {
                showMessage('Linkki vuokrasopimukseen lisätty');
                onSave();
            })
            .catch(console.error);
    };

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        type='url'
                        id='contractlink'
                        name='contractlink'
                        label='Linkki vuokrasopimukseen'
                        fullWidth
                        variant='filled'
                        value={ctr}
                        margin='normal'
                        onChange={(event) => setCtr(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Peruuta
                     </Button>
                    <Button onClick={() => addContract()} color="primary">
                        Lisää
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
