import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MailPreview from './MailPreview';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import { useFetch } from '../common/hooks';

const Mailing = ({ mall, user }) => {
    const [{ data: userData }] = useFetch(`${process.env.REACT_APP_API}/users/list`);
    const users = userData ? userData.items : [];
    const roles = userData ? userData.roles : [];
    const [{ data: resourceListData }] = useFetch(`${process.env.REACT_APP_API}/resourceowner/${mall.id}`);
    const resourcelists = resourceListData ? resourceListData.items.filter((item) => resourceListData.resources.includes(item.id)) : [];
    const resources = resourceListData ? resourceListData.items : [];
    const [selectedRoles, setSelectedRoles] = useState([]);
    useEffect(() => {
        setRecipients(users.filter((item) => item.behaviors.privileged.contexts.some((ctx) => selectedRoles.includes(ctx.role)) || user.email === item.email));
    }, [selectedRoles, users, user]);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [recipients, setRecipients] = useState([]);
    const [recipientSkip, setRecipientSkip] = useState([]);
    const [attachedLists, setAttachedLists] = useState([]);
    const [attachments, setAttachments] = useState([]);
    useEffect(() => {
        setAttachments(resources
            .filter((resource) => resources
                .filter((resource) => attachedLists.includes(resource.id))
                .flatMap((resource) => resource.behaviors.resourceowner.resources)
                .includes(resource.id)));
    }, [resources, attachedLists]);
    useEffect(() => {
        const savedMailRaw = window.sessionStorage.getItem('mail');
        if (savedMailRaw) {
            const savedMail = JSON.parse(savedMailRaw);
            setSelectedRoles(savedMail.selectedRoles);
            setTitle(savedMail.title);
            setBody(savedMail.body);
            setAttachedLists(savedMail.attachedLists);
        }
    }, []);
    useEffect(() => {
        window.sessionStorage.setItem('mail', JSON.stringify({ selectedRoles, title, body, attachedLists }));
    }, [selectedRoles, title, body, attachedLists]);
    const [previewOpen, setPreviewOpen] = useState(false);

    const attachFileList = (list) => {
        setAttachedLists([...attachedLists, list]);
    };

    const detachFileList = (list) => {
        setAttachedLists(attachedLists.filter((value) => value !== list));
    };

    const skipRecipient = (recipient) => () => {
        setRecipientSkip(recipientSkip.includes(recipient) ? recipientSkip.filter((user) => user !== recipient) : [...recipientSkip, recipient]);
    };

    const sendMail = () => {
        const finalRecipients = recipients.filter((user) => !recipientSkip.includes(user.id));

        fetch(`${process.env.REACT_APP_API}/emailing/compose`, {
            method: 'POST',
            body: JSON.stringify({
                message: {
                    subject: title,
                    body: bodyWithAttachments()
                },
                recipients: finalRecipients.map((user) => user.id)
            })
        })
            .then((response) => {
                setPreviewOpen(false);
                showMessage('Viesti lähetetty');
                // Reset form
                setTitle('');
                setBody('');
                setAttachedLists([]);
                setSelectedRoles([]);
                setRecipientSkip([]);
                setAttachments([]);
            })
            .catch(console.error);
    };

    const bodyWithAttachments = () => {
        let newBody = body;
        newBody += '\n\n\nTämä viesti on lähetetty Datahallinnasta. Tähän viestiin ei voi vastata.';
        if (attachments.length > 0) {
            attachments.forEach((attachment) => {
                newBody += '\n\n';

                // FIXME we should have primaryString.title and linkable.url
                // .. or some resource class to move responsibility into
                switch (attachment.type) {
                    case 'externallink':
                        newBody += `${attachment.title}\n`;
                        newBody += `${attachment.url}`;
                        break;
                    case 'file':
                        newBody += `${attachment.name.replace(/\.[^/.]+$/, '')}\n`;
                        newBody += `${process.env.REACT_APP_API}/filestore/download/attachment/${attachment.id}/${attachment.name}`;
                        break;
                    default:
                        break;
                }
            });
        }
        return newBody;
    };

    const messageComposed = title.length > 0 && body.length > 0;

    return (
        <Container maxWidth={false}>
            <Toolbar disableGutters>
                <Typography variant='h4'>
					Vuokralaisviestintä
                </Typography>
            </Toolbar>

            <Grid container spacing={2} direction='column'>
                <Grid item xs={12} lg={8}>
                    <Toolbar disableGutters>
                        <Typography variant='h6'>
							Viesti
                        </Typography>
                    </Toolbar>
                    <Paper style={{ padding: '1em' }}>
                        <TextField
                            id='title'
                            name='title'
                            label='Otsikko'
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            InputLabelProps={{ shrink: true }}
                            margin='normal'
                            fullWidth
                        />

                        <TextField
                            id='body'
                            name='body'
                            label='Viesti'
                            value={body}
                            onChange={(event) => setBody(event.target.value)}
                            InputLabelProps={{ shrink: true }}
                            margin='normal'
                            fullWidth
                            multiline
                            rows='10'
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Toolbar disableGutters>
                        <Typography variant='h6'>
							Liiteryhmät
                        </Typography>
                    </Toolbar>
                    <Paper>
                        <List>
                            {resourcelists.map((resourcelist) => (
                                <ListItem key={resourcelist.id}>
                                    <ListItemText disableTypography>
                                        <Typography variant='body2'>{resourcelist.title || <small>&lt;no name&gt;</small>}</Typography>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => attachedLists.includes(resourcelist.id) ? detachFileList(resourcelist.id) : attachFileList(resourcelist.id)}
                                        >
                                            {attachedLists.includes(resourcelist.id) ? 'Poista' : 'Lisää' }
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Toolbar disableGutters>
                        <Typography variant='h6'>
							Vastaanottajat
                        </Typography>
                    </Toolbar>
                    <Paper style={{ padding: '1em' }}>
                        <Toolbar disableGutters>
                            <FormControl style={{ flexGrow: 1 }}>
                                <InputLabel shrink>Roolit</InputLabel>
                                <Select
                                    multiple
                                    value={selectedRoles}
                                    onChange={(event) => setSelectedRoles(event.target.value)}
                                >
                                    {roles.map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant='contained'
                                color='primary'
                                style={{ marginLeft: '1em', flexShrink: 0 }}
                                onClick={() => setSelectedRoles(roles.map((item) => item.id))}
                            >
								Kaikki
                            </Button>
                        </Toolbar>
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Button variant='contained' color='primary'
                        disabled={!messageComposed || recipients.length === 0}
                        onClick={() => setPreviewOpen(true)}
                    >
						Esikatsele
                    </Button>
                </Grid>
            </Grid>

            <MailPreview
                open={previewOpen}
                title={title}
                body={body}
                recipients={recipients}
                recipientSkip={recipientSkip}
                attachments={attachments}
                onClose={() => setPreviewOpen(false)}
                onSave={sendMail}
                onCheckRecipient={skipRecipient}
            />
        </Container>
    );
};

export default Mailing;
