import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';

const AddLinkDialog = ({ open, resourcelist, onClose, onSave }) => {
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');

    const addLink = () => {
        fetch(`${process.env.REACT_APP_API}/resourceowner/${resourcelist}/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                resource: {
                    type: 'externallink',
                    url: url,
                    title: title,
                }
            })
        })
            .then((resource) => {
                showMessage('Linkki lisätty');
                onSave();
            })
            .catch((error) => {
                showMessage('Linkin lisääminen epäonnistui', true);
                console.error(error);
            });
    };

    return (
        <Dialog open={open}>
            <DialogTitle>
				Lisää linkki
            </DialogTitle>
            <DialogContent>
                <TextField
                    type='text'
                    id='url'
                    name='url'
                    label='Osoite'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    value={url}
                    onChange={(event) => setUrl(event.target.value)}
                />
                <TextField
                    type='text'
                    id='title'
                    name='title'
                    label='Nimi'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Peruuta
                </Button>
                <Button color='primary' onClick={addLink}>
                    Lisää
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddLinkDialog;
