import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';

const IEnotifier = ({ theme }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const ua = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(ua);
        if ( isIE ) {
            setOpen(true);
        }
    }, []);

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <SnackbarContent
                style={{ backgroundColor: theme.palette.error.dark }}
                message='Huomasimme, että käytät Internet Exploreria. Tässä sovelluksessa on havaittu ongelmia kyseisellä selaimella. Suosittelemme käyttämään moderneja selaimia kuten Microsoft Edge, Google Chrome tai Mozilla Firefox.'
                action={
                    <Button color='inherit' size='small' onClick={handleClose}>
                        Sulje
                    </Button>
                }
            />
        </Snackbar>
    );
};

export default withTheme(IEnotifier);
