const desc = (a, b, orderBy) => {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
    }
    return 0;
};

const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

const stableSort = (array, sortOrderBy, sortOrder = 'asc') => {
    const cmp = getSorting(sortOrder, sortOrderBy);
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export default stableSort;
