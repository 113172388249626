import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { monthOptions } from '../constants';
import { showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';

const ReportDialog = ({ open, title, report, store, onClose, onSave }) => {
    const [id, setId] = useState(null);
    const [date, setDate] = useState(`${new Date().toISOString().substr(0, 7)}-01`);
    const [sales, setSales] = useState('0.00');
    const [receiptcount, setReceipcount] = useState(0);
    const [visitorcount, setVisitorcount] = useState(0);
    const [turnover, setTurnover] = useState('0.00');
    useEffect(() => {
        if (open) {
            if (report) {
                setId(report.id);
                setDate(report.date);
                setSales(report.sales || '0.00');
                setReceipcount(report.receiptcount || 0);
                setVisitorcount(report.visitorcount || 0);
                setTurnover(report.turnover || '0.00');
            } else {
                setId(null);
                setDate(`${new Date().toISOString().substr(0, 7)}-01`);
                setSales('0.00');
                setReceipcount(0);
                setVisitorcount(0);
                setTurnover('0.00');
            }
        }
    }, [open, report]);

    const parseNumber = (number) => {
        // Remove non numbers
	    let result = number.trim().replace(/[^0-9]/g, '');
        // Ends with . or , and 1 number
        if (/[,.]\d{1}$/.test(number)) {
            result = result.replace(/(\d{1})$/, '.$1');
        }
        // Ends with . or , and 2 numbers
	    if (/[,.]\d{2}$/.test(number)) {
	        result = result.replace(/(\d{2})$/, '.$1');
	    }
	    return parseFloat(result);
    };

    const addReport = () => {
        fetch(`${process.env.REACT_APP_API}/store/${store.id}/sales`, {
            method: 'POST',
            body: JSON.stringify({
                report: {
                    date,
                    sales: parseNumber(sales),
                    turnover: parseNumber(turnover),
                    receiptcount,
                    visitorcount
                }
            })
        })
            .then((response) => {
                showMessage('Raportti lisätty');
                onSave();
            })
            .catch(console.error);
    };

    const updateReport = () => {
        fetch(`${process.env.REACT_APP_API}/store/${store.id}/sales`, {
            method: 'PUT',
            body: JSON.stringify({
                report: {
                    id,
                    date,
                    sales: parseNumber(sales),
                    turnover: parseNumber(turnover),
                    receiptcount,
                    visitorcount
                }
            })
        })
            .then((response) => {
                showMessage('Raportti päivitetty');
                onSave();
            })
            .catch(console.error);
    };

    const updateYear = (event) => {
        const year = `000${event.target.value}`.slice(-4);
        setDate(`${year}-${date.split('-')[1]}-${date.split('-')[2]}`);
    };

    const updateMonth = (event) => {
        const month = `0${event.target.value + 1}`.slice(-2);
        setDate(`${date.split('-')[0]}-${month}-${date.split('-')[2]}`);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>
                {report && report.id ? 'Muokkaa raporttia' : 'Lisää raportti'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            required
                            id='month'
                            name='month'
                            label='Kuukausi'
                            fullWidth
                            variant='filled'
                            margin='normal'
                            value={parseInt(date.split('-')[1]) - 1}
                            onChange={updateMonth}
                        >
                            {monthOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            type='number'
                            id='year'
                            name='year'
                            label='Vuosi'
                            fullWidth
                            variant='filled'
                            margin='normal'
                            value={date.split('-')[0]}
                            onChange={updateYear}
                        />
                    </Grid>
                </Grid>
                <TextField
                    required
                    type='text'
                    id='sales'
                    name='sales'
                    label='Kuukausimyynti (sis. alv)'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    value={sales}
                    onChange={(event) => setSales(event.target.value)}
                    InputLabelProps={{
                        style: { textTransform: 'inherit' }
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment disableTypography={true} position='end'>€</InputAdornment>,
                    }}
                />
                <TextField
                    required
                    type='text'
                    id='turnover'
                    name='turnover'
                    label='Kuukausiliikevaihto (ei sis alv)'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    value={turnover}
                    onChange={(event) => setTurnover(event.target.value)}
                    InputLabelProps={{
                        style: { textTransform: 'inherit' }
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment disableTypography={true} position='end'>€</InputAdornment>,
                    }}
                />
                <TextField
                    required
                    type='number'
                    id='receiptcount'
                    name='receiptcount'
                    label='Kuitit'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    value={receiptcount}
                    onChange={(event) => setReceipcount(event.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment disableTypography={true} position='end'>kpl</InputAdornment>,
                    }}
                />
                <TextField
                    type='number'
                    id='visitorcount'
                    name='visitorcount'
                    label='Kävijämäärä'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    value={visitorcount}
                    onChange={(event) => setVisitorcount(event.target.value)}
                    style={{ display: (process.env.REACT_APP_NAME === 'grani' || process.env.REACT_APP_NAME === 'martinlaakso') && 'none' }}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                	Peruuta
                </Button>
                <Button color='primary' onClick={() => report && report.id ? updateReport() : addReport()}>
                    {report && report.id ? 'Tallenna' : 'Lisää'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReportDialog;
