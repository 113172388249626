import { showMessage } from '../Notifier';

const fetchWrap = (url, options) => {
    const init = {
        ...options,
        credentials: 'include',
    };
    if (!init.headers) {
        init.headers = {};
    }
    // Allow browser to determine content-type header of payload is instance of FormData
    if (!init.headers['Content-Type'] && !(init.body instanceof FormData)) {
        init.headers['Content-Type'] = 'application/json';
    }
    if (!init.headers['Cache-Control']) {
        init.headers['Cache-Control'] = 'no-cache';
        init.headers['Pragma'] = 'no-cache';
    }
    return fetch(url, init)
        .then((response) => {
            if (!response.ok) {
                // User is not logged in or session has expired
                if (response.status === 401) {
                    window.location.href = '/';
                }
                throw new Error(response.status);
            }
            return response;
        })
        .then((response) => response.json())
        .catch((error) => {
            switch(error.message) {
                case '400':
                {
                    showMessage('Virheellinen syöte', true);
                    break;
                }
                case '401':
                {
                    showMessage('Istuntosi on vanhentunut', true);
                    break;
                }
                case '403':
                {
                    showMessage('Sinulla ei ole oikeuksia suorittaa tätä toimintoa', true);
                    break;
                }
                case '404':
                {
                    showMessage('Valittua kohdetta ei löytynyt', true);
                    break;
                }

                default:
                {
                    showMessage('Tuntematon virhe', true);
                    break;
                }
            }
            throw error;
        });
};

export default fetchWrap;
