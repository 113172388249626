import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

const MailPreview = ({ open, onClose, onSave, onCheckRecipient, title, body, attachments, recipients, recipientSkip }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog open={open} fullWidth maxWidth='md' fullScreen={fullScreen}>
            <DialogTitle disableTypography>
                <Typography variant='h5'>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant='body1' style={{ whiteSpace: 'pre-wrap' }}>
                    {body}
                </Typography>

                {attachments.length > 0 && (
					<>
						<Toolbar disableGutters>
						    <Typography variant='h5'>
								Liitteet
						    </Typography>
						</Toolbar>
						<Grid container spacing={2} style={{ maxHeight: fullScreen ? 'none' : '300px', overflow: 'auto' }}>
						    {attachments.map((attachment) => (
						        <Grid item xs={12} key={attachment.id}>
						            {attachment.name || attachment.title}
						        </Grid>
						    ))}
						</Grid>
					</>
                )}

                <Toolbar disableGutters>
                    <Typography variant='h5'>
						Vastaanottajat
                    </Typography>
                </Toolbar>
                <Grid container spacing={2} style={{ maxHeight: fullScreen ? 'none' : '300px', overflow: 'auto' }}>
                    {recipients.map((recipient) => (
                        <Grid item key={recipient.id}>
                            <Chip clickable
                                label={recipient.email}
                                color={recipientSkip.find((user) => user === recipient.id) ? 'default' : 'secondary'}
                                onClick={onCheckRecipient(recipient.id)}
                                style={{ margin: '0 0.5em' }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
		        <Button color='primary' onClick={onClose}>
		            Peruuta
		        </Button>
		        <Button color='primary' onClick={() => onSave()}>
		            Lähetä
		        </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MailPreview;
