import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { useFetch } from '../common/hooks';
import Hours from './Hours';

const General = ({ mall }) => {
    const [{ data, refresh }] = useFetch(`${process.env.REACT_APP_API}/mall/${mall.id}`);

    if (!data) return null;

    const openingtimes = data.items.find((item) => item.type === 'mall').behaviors.openingtimes;

    return (
        <Container maxWidth={false}>
            <Hours
                store={mall}
                defaultTimes={openingtimes}
                onDefaultTimesChanged={refresh}
            />
        </Container>
    );
};

General.propTypes = {
    mall: PropTypes.object.isRequired,
};

export default General;
