import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import TimeSelector from './TimeSelector';

const RangeSelector = ({ theme, range, onChange }) =>  {
    const [newRange, setNewRange] = useState(range);
    const changeWrapper = (beforeDash) => (value) => {
        let orig = range.split('-');
        let temp = [...orig];
        temp[beforeDash ? 0 : 1] = value;
        setNewRange(temp.join('-'));
        onChange(temp.join('-'));
    };

    const handleToggle = (event) => {
        // if day had range, we null it (close) and otherwise set some default to re-enable it
        onChange(range ? null : (newRange || '09.00-18.00'));
    };

    return (
        <Grid container alignItems='center' justify='space-between'>
            {range &&
				<>
					<TimeSelector timestamp={range.split('-')[0]} onChange={changeWrapper(true)}/>
					<span>–</span>
					<TimeSelector timestamp={range.split('-')[1]} onChange={changeWrapper(false)}/>
				</>
            }
            {!range &&
				<span style={{ flexGrow: 1, textAlign: 'center' }}>
					suljettu
				</span>
            }
            <Checkbox
                checked={range !== undefined}
                onChange={handleToggle}
                style={theme.checkBox}
            />
        </Grid>
    );
};

export default RangeSelector;
